import { rtdb } from '@/firebase';

const state = {
  tournamentSponsorships: [],
  tournamentSponsorshipsLoaded: false,
  tournamentCredits: [],
  lastTournamentCreditsKey: null,
  tournamentCreditsLoaded: false,
  tournamentCreditsListener: null,
  tournamentCandidates: [],
  lastTournamentCandidatesKey: null,
  tournamentCandidatesListener: null,
  tournamentCandidatesLoaded: false,
  tournamentActivity: [],
  lastActivityKey: null,
  isActivitiesLoaded: false,
  tournamentBets: [],
};

const getters = {
  tournamentBets: (state) => state.tournamentBets,
};

const mutations = {
  UPDATE_TOURNAMENTS_SPONSORSHIPS(state, { tournamentSponsorships }) {
    state.tournamentSponsorships = tournamentSponsorships;
  },

  TOURNAMENTS_SPONSORSHIPS_LOADED(state) {
    state.tournamentSponsorshipsLoaded = true;
  },
  UPDATE_TOURNAMENTS_CREDITS(state, { tournamentsCredits, lastKey }) {
    state.tournamentCredits = tournamentsCredits;
    state.lastTournamentCreditsKey = lastKey;
  },

  TOURNAMENTS_CREDITS_LOADED(state) {
    state.tournamentCreditsLoaded = true;
  },

  SET_TOURNAMENTS_CREDITS_LISTENER(state, listener) {
    state.tournamentCreditsListener = listener;
  },
  UPDATE_ACTIVITIES(state, { activities }) {
    state.tournamentActivity = activities; // Assuming you want to append new activities
  },
  UPDATE_LAST_ACTIVITY_KEY(state, key) {
    state.lastActivityKey = key;
  },
  ACTIVITIES_LOADED(state) {
    state.isActivitiesLoaded = true;
  },
  SET_TOURNAMENT_BETS(state, results) {
    state.tournamentBets = results;
  },
  UPDATE_CANDIDATES(state, { candidates, lastKey }) {
    state.tournamentCandidates = candidates;
    state.lastTournamentCandidatesKey = lastKey;
  },

  CANDIDATES_LOADED(state) {
    state.tournamentCandidatesLoaded = true;
  },

  SET_CANDIDATES_LISTENER(state, listener) {
    state.tournamentCandidatesListener = listener;
  },
};
const actions = {
  async GET_RTDB_TOURNAMENT_SPONSORSHIPS({ commit }, tournamentDocId) {
    try {
      const tournamentSponsorshipsRef = rtdb
        .ref('tournamentsSponsorships')
        .orderByChild('tournamentDocId')
        .equalTo(tournamentDocId);

      // Perform a one-time fetch to get data
      const snapshot = await tournamentSponsorshipsRef
        .limitToFirst(5)
        .once('value');
      const tournamentSponsorships = [];

      snapshot.forEach((childSnapshot) => {
        const sponsorship = childSnapshot.val();
        if (sponsorship.isActionValid === true) {
          tournamentSponsorships.push({
            ...sponsorship,
            sponsorDocId: childSnapshot.key,
          });
        }
      });

      // Update sponsorships in state
      commit('UPDATE_TOURNAMENTS_SPONSORSHIPS', { tournamentSponsorships });
      commit('TOURNAMENTS_SPONSORSHIPS_LOADED');

      // Real-time listener setup without unsubscribe
      tournamentSponsorshipsRef.on('value', (snapshot) => {
        const updatedSponsorships = [];
        snapshot.forEach((childSnapshot) => {
          const sponsorship = childSnapshot.val();
          if (sponsorship.isActionValid === true) {
            updatedSponsorships.push({
              ...sponsorship,
              sponsorshipDocId: childSnapshot.key,
            });
          }
        });
        commit('UPDATE_TOURNAMENTS_SPONSORSHIPS', {
          tournamentSponsorships: updatedSponsorships,
        });
      });
    } catch (error) {
      console.error('Error in fetching sponsorships:', error);
      commit('UPDATE_TOURNAMENTS_SPONSORSHIPS', {
        tournamentSponsorships: [],
      });
      commit('TOURNAMENTS_SPONSORSHIPS_LOADED');
    }
  },
  async GET_RTDB_TOURNAMENT_CREDITS({ commit }, tournamentDocId) {
    try {
      const tournamentsCreditsRef = rtdb
        .ref('tournamentsCredits')
        .orderByChild('tournamentDocId')
        .equalTo(tournamentDocId);

      // Perform a one-time fetch to get data
      const snapshot = await tournamentsCreditsRef
        .limitToFirst(5)
        .once('value');
      const tournamentsCredits = [];

      snapshot.forEach((childSnapshot) => {
        const credit = childSnapshot.val();
        if (credit.isActionValid === true) {
          tournamentsCredits.push({
            ...credit,
            creditDocId: childSnapshot.key,
          });
        }
      });

      // Update credits in state
      commit('UPDATE_TOURNAMENTS_CREDITS', { tournamentsCredits });
      commit('TOURNAMENTS_CREDITS_LOADED');

      // Real-time listener setup without unsubscribe
      tournamentsCreditsRef.on('value', (snapshot) => {
        const updatedCredits = [];
        snapshot.forEach((childSnapshot) => {
          const credit = childSnapshot.val();
          if (credit.isActionValid === true) {
            updatedCredits.push({
              ...credit,
              creditDocId: childSnapshot.key,
            });
          }
        });
        commit('UPDATE_TOURNAMENTS_CREDITS', {
          tournamentsCredits: updatedCredits,
        });
      });
    } catch (error) {
      console.error('Error in fetching credits:', error);
      commit('UPDATE_TOURNAMENTS_CREDITS', { tournamentsCredits: [] });
      commit('TOURNAMENTS_CREDITS_LOADED');
    }
  },
  async GET_RTDB_TOURNAMENT_ACTIVITY({ commit }, tournamentDocId) {
    let activityRef = rtdb.ref('tournamentsActivity');

    try {
      // Set up real-time listener
      activityRef
        .orderByChild('tournamentDocId')
        .equalTo(tournamentDocId)
        .on(
          'value',
          (snapshot) => {
            let activities = [];

            snapshot.forEach((child) => {
              activities.push({
                ...child.val(),
                docId: child.key,
              });
            });

            // Commit updates whenever data changes
            commit('UPDATE_ACTIVITIES', { activities });
            commit('ACTIVITIES_LOADED');
          },
          (error) => {
            // Error callback for the listener
            console.error('Error in real-time activities listener:', error);
            commit('UPDATE_ACTIVITIES', { activities: [] });
            commit('ACTIVITIES_LOADED');
          }
        );
    } catch (error) {
      console.error('Error setting up real-time listener:', error);
      commit('UPDATE_ACTIVITIES', { activities: [] });
      commit('ACTIVITIES_LOADED');
    }
  },

  async GET_RTDB_TOURNAMENT_CANDIDATES({ commit, state }, tournamentDocId) {
    let candidatesRef = rtdb
      .ref('tournamentsCandidates')
      .orderByChild('tournamentDocId')
      .equalTo(tournamentDocId);

    if (state.lastCandidatesKey) {
      candidatesRef = candidatesRef.startAfter(state.lastCandidatesKey);
    }

    try {
      // Perform a one-time fetch to get initial or next data
      const snapshot = await candidatesRef.limitToFirst(5).once('value');
      const candidates = [];

      snapshot.forEach((childSnapshot) => {
        const candidate = childSnapshot.val();

        if (candidate.isActionValid === true) {
          candidates.push({
            ...candidate,
            candidateDocId: childSnapshot.key,
          });
        }
      });

      // Update candidates and last key in state
      // We need to ensure lastKey reflects a valid candidate if any exist
      let lastKey = null;
      if (candidates.length > 0) {
        lastKey = candidates[candidates.length - 1].candidateDocId;
      }

      commit('UPDATE_CANDIDATES', {
        candidates,
        lastKey: lastKey,
      });
      commit('CANDIDATES_LOADED');

      // Real-time listener setup if needed
      const unsubscribe = candidatesRef.on('value', (snapshot) => {
        const candidates = [];
        snapshot.forEach((childSnapshot) => {
          const candidate = childSnapshot.val();
          if (candidate.isActionValid === true) {
            candidates.push({
              ...candidate,
              candidateDocId: childSnapshot.key,
            });
          }
        });
        let lastKey = null;
        if (candidates.length > 0) {
          lastKey = candidates[candidates.length - 1].candidateDocId;
        }
        commit('UPDATE_CANDIDATES', { candidates, lastKey });
      });
      commit('SET_CANDIDATES_LISTENER', unsubscribe);
    } catch (error) {
      console.error('Error in fetching candidates:', error);
      commit('UPDATE_CANDIDATES', { candidates: [], lastKey: null });
      commit('CANDIDATES_LOADED');
    }
  },
  GET_TOURNAMENT_BETS({ commit }, tournamentDocId) {
    const ref = rtdb.ref(`/tournaments/${tournamentDocId}`);

    // Set up a listener for value changes
    const unsubscribe = ref.on('value', (snapshot) => {
      if (!snapshot.exists()) {
        console.warn(`No bets found for tournament: ${tournamentDocId}`);
        commit('SET_TOURNAMENT_BETS', []);
      } else {
        commit('SET_TOURNAMENT_BETS', snapshot.val());
      }
    });

    // Return the unsubscribe function so it can be called if needed
    return () => {
      unsubscribe();
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
