import Vue from 'vue';
import Vuex from 'vuex';
import { db, rtdb, fb, twitterProvider } from '@/firebase';
import router from '../router/router';
import UserStore from './_userStore/UserStore.js';
import ExpStore from './otherStores/ExpStore.js';
import RanksStore from './rankingsStore/RanksStore.js';
import SubscriptionsStore from './subscriptionsStores/SubscriptionsStore.js';
import RtdbSubscriptionsStore from './subscriptionsStores/RtdbSubscriptionsStore.js';
import AffiliatesStore from './otherStores/AffiliatesStore.js';
import TwitterStore from './otherStores/TwitterStore.js';
import GlobalStore from './GlobalStore.js';
import BetsStore from './betsStore/BetsStore';

import AdminStore from './AdminStore.js';
import TourneysStore from './tourneysStore/TourneysStore.js';
import RtdbTourneysStore from './tourneysStore/RtdbTourneysStore.js';
import AccountStore from './_accountStore/AccountStore.js';
import AccountTournamentsStore from './_accountStore/accountTournaments/AccountTournamentsStore.js';
import AccountRtdbTournamentsStore from './_accountStore/accountTournaments/AccountRtdbTournamentsStore.js';

import AccountRtdbStore from './_accountStore/AccountRtdbStore.js';
import moment from 'moment';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    UserStore,
    AccountStore,
    ExpStore,
    RanksStore,
    SubscriptionsStore,
    TourneysStore,
    TwitterStore,
    AffiliatesStore,
    RtdbTourneysStore,
    RtdbSubscriptionsStore,
    GlobalStore,
    AdminStore,
    AccountRtdbStore,
    AccountTournamentsStore,
    AccountRtdbTournamentsStore,
    BetsStore,
  },

  state: {
    profileFortniteBuildsLevel: null,
    profileFortniteUsername: null,
    profileRank: null,
    profileYoutubeShorts: null,
    profileYoutubeVideos: null,
    profileYoutubeVods: null,
    profileStepsTime: null,
    challengeInterval: null,
    challengeStepsTime: null,
    challengeTime: null,
    challengeShowDaily: false,
    stepCompleted: false,
    stepLoading: false,
    challengeStepsCompleted: null,
    currentExpList: {},
    emotePhoto: null,
    adminAllTourneys: [],
    coinsRankings: [],
    rtdbSelectedGiveawaysWinners: null,
    tourney: {},
    rtdbAllBettings: {},
    filteredBettings: [],
    filteredBettingsValue: 0,
    filteredBoosts: [],
    filteredBoostsValue: 0,
    rtdbCoinsParticipants: [],
    candidateParticipant: false,
    coinsParticipant: false,
    rtdbCoinsBoosts: [],
    rtdbGiveaway: {},
    rtdbSelectedCoinsWinners: [],
    rtdbSelectedPrizeWinners: [],
    rtdbSelectedPrizesClaimed: false,
    rtdbSelectedPrizesWinners: [],
    selectedRtdbTourney: {},
    accountTourneys: [],
    accountTourneySelected: {},
    accountTourneyApplications: [],
    accountTourney: {},
    currentAffiliate: {},
    currentAffiliateIndex: 0,
    accountAllGiveaways: [],
    accountExp: {},
    affiliateExp: [],
    accountExpTweets: [],
    affiliateExpTweets: [],

    accountViewers: [],
    accountExpList: [],
    accountTweetsRequests: [],
    accountSelectedRequestsTypes: [],
    affiliateTweetsRequests: [],
    accountRankings: [],
    accountsState: [],
    accountViewersCount: 0,
    profileRanksLoaded: false,
    team: [],
    articles: [],
    article: {},
    advertisingSpots: [],
    ranksApplications: [],
    tickets: [],
    currentTicketProfile: {},
    currentUnreadMessages: 0,
    currentNotifications: 0,
    currentTicket: {},
    currentTicketIndex: 0,
    profileTickets: {},
    store: [],
    usersLevels: [],
    events: [],
    eventsHours: [...Array(24).keys()],
    availableWeekdays: [],
    currentWeeks: [],
    todayEvents: [],
    profileCurrentGiveaway: {},
    profileCurrentParticipants: [],
    streamersList: [],
    streamersProList: [],
    streamerXpList: null,
    streamerLoaded: false,
    accountTweetsRequestsLoaded: false,
    accountWonTourneys: [],
    allExpTourneys: [],
    buildPresenceLoaded: false,
    accountWonGiveaways: [],
    affiliatesExpTweets: [],
    displayAllActiveGiveaways: [],
    allActiveGiveaways: [],
    allFortniteGiveaways: [],
    liveStreamer: {},
    followerEmotes: [],
    tierIEmotes: [],
    tierIIEmotes: [],
    tierIIIEmotes: [],
    bitsEmotes: [],
    xmasEmotes: [],
    emotes: [],
    route: null,
    routeName: null,
    routeHash: null,
    validUsername: false,
    nonUsername: false,
    checkEmail: false,
    scheduledMerch: [],
    allScheduledMerch: [],
    selectedAdminApplications: [],
    rtdbActiveSubscriptions: [],

    // LOADED STATE SECTION

    routeLoaded: false,
    teamLoaded: false,
    creditsRankings: [],
    creditsRankingsLoaded: false,
    accountLoaded: false,
    streamersLoaded: false,
    expLoaded: false,
    onlineLoaded: false,
    viewersLoaded: false,
    rtdbGiveawayLoaded: false,
    ticketsLoaded: false,
    profileTicketsLoaded: false,
    tourneyLoaded: false,
    affiliatesExpLoaded: false,
    accountGiveawaysLoaded: false,
    accountTourneysLoaded: false,
  },
  getters: {
    profileMode(state) {
      return state.profileMode;
    },
    profileState(state) {
      return state.profileState;
    },
    userPhotoUrl(state) {
      return state.userPhotoUrl;
    },
    username(state) {
      return state.username;
    },
    accounts(state) {
      return state.accounts;
    },
    events(state) {
      return state.accounts;
    },
    todayEvents(state) {
      return state.todayEvents;
    },
    user: (state) => {
      return !!state.user;
    },
  },
  mutations: {
    setCounter(state) {
      state.stepLoading = true;
      state.challengeInterval = setInterval(() => {
        state.challengeStepsTime = moment(
          state.challengeStepsTime.subtract(1, 'seconds')
        );
        state.challengeTime = state.challengeStepsTime.format('mm:ss');
        if (state.challengeTime === '00:00') {
          state.stepCompleted = true;
          clearInterval(state.challengeInterval);
        }
      }, 1000);
    },
    SET_ROUTE(state, route) {
      state.route = route.params.id;
      state.routeName = route.name;
      state.routeHash = route.hash;
    },

    SET_ACCOUNT_EXP_RANKINGS(state, payload) {
      state.accountRankings.push(payload);
    },

    SET_AFFILIATES_EXP(state, payload) {
      if (payload.username !== state.username) {
        state.affiliatesExpTweets.push(payload);
      }
    },

    UPDATE_PROFILE_EXP_AMOUNT(state, payload) {
      const index = state.accountExpList.findIndex((item) => {
        return item.username === state.username;
      });
      if (index !== -1) {
        state.accountExpList[index].userExp =
          state.accountExpList[index].userExp - payload;
      }
    },
    SET_SCHEDULE_MERCH(state, payload) {
      state.allScheduledMerch.push(payload);
    },
    CLEAR_CHALLENGE_COUNTDOWN(state) {
      clearInterval(state.challengeInterval);
      state.challengeInterval = null;
    },

    UPDATE_ASSIGNED_APPLICATION(state, payload) {
      const index = payload.applications.findIndex((event) => {
        return event.username === payload.application.username;
      });
      payload.applications[index].assigned_to = state.username;
      payload.applications[index].assigned = true;
      state.selectedAdminApplications = payload.applications;
    },

    SET_PROFILE_RANKS(state, payload) {
      state.profileRanks = payload.data();
      state.profileFortniteBuildsLevel = payload.data().fortniteBuildsLevel;
    },
    SET_STREAMERS_SCHEDULE(state, payload) {
      state.streamersList = payload.data().list;
      state.streamersProList = payload.data().proList;
    },
    UPDATE_DAILY_STATUS(state) {
      state.challengeShowDaily = false;
    },
    UPDATE_STEPS_COMPLETED(state, payload) {
      state.challengeStepsTime = null;
      state.challengeTime = null;
      state.challengeStepsCompleted = state.challengeStepsCompleted + 1;
      let today = moment();
      let todayFormat = moment(today).format('YY/MM/DD');
      if (todayFormat >= state.currentExpList.cooldown) {
        if (state.challengeStepsCompleted === 1) {
          state.challengeStepsTime = moment((1000 * 60) / 2);
          state.challengeTime = state.challengeStepsTime.format('mm:ss');
        } else if (state.challengeStepsCompleted === 2) {
          state.challengeStepsTime = moment(1000 * 60 * 2);
          state.challengeTime = state.challengeStepsTime.format('mm:ss');
        } else if (state.challengeStepsCompleted === 3) {
          state.challengeStepsTime = moment(1000 * 60 * 5);
          state.challengeTime = state.challengeStepsTime.format('mm:ss');
        }
        state.challengeShowDaily = true;
      }
      state.currentExpList.totalExp = state.currentExpList.totalExp + payload;
      state.stepLoading = false;
      state.stepCompleted = false;
    },
    UPDATE_DISCORD_ID(state, payload) {
      state.profileDiscordId = payload;
    },

    UPDATE_TWITTER_USERNAME(state, payload) {
      state.profileTwitterUsername = payload.username;
      state.profileTwitterStatus = payload.status;
    },
    UPDATE_TOURNEY_TWITTER_STEP(state, payload) {
      const index = state.accountTourneys.findIndex((tourney) => {
        return tourney.docId === payload.docId;
      });
      state.accountTourneys[index].tweetStep = payload.step;
      state.currentTweetStep = payload.step;
    },
    UPDATE_TOURNEY(state, payload) {
      const index = state.accountTourneys.findIndex((tourney) => {
        return tourney.docId === payload.docId;
      });
      if (index !== -1) {
        state.accountTourneys[index] = payload;
      }
    },
    UPDATE_GIVEAWAY(state, payload) {
      const index = state.accountAllGiveaways.findIndex((giveaway) => {
        return giveaway.docId === payload.docId;
      });
      state.accountAllGiveaways[index] = payload;
    },
    UPDATE_GIVEAWAY_TWITTER_STEP(state, payload) {
      const index = state.accountAllGiveaways.findIndex((giveaway) => {
        return giveaway.docId === payload.docId;
      });
      state.accountAllGiveaways[index].tweetStep = payload.step;
      state.currentTweetStep = payload.step;
    },
    UPDATE_TWITTER_EXP_STEP(state) {
      if (state.accountExp && state.accountExp.currentStep === undefined) {
        state.accountExp.step = 1;
        state.accountExp.currentStep = 1;
      } else {
        state.accountExp.step = state.accountExp.step + 1;
        state.accountExp.currentStep = state.accountExp.currentStep + 1;
      }
    },
    UPDATE_AFFILIATE_TWITTER_EXP_STEP(state, payload) {
      const index = state.affiliateExp.findIndex((affiliate) => {
        return affiliate.userId === payload;
      });
      state.currentAffiliateIndex = index;
      if (
        state.currentAffiliate &&
        state.currentAffiliate.currentStep === undefined
      ) {
        state.currentAffiliate.step = 1;
        state.currentAffiliate.currentStep = 1;
      } else {
        state.currentAffiliate.step = state.currentAffiliate.step + 1;
        state.currentAffiliate.currentStep =
          state.currentAffiliate.currentStep + 1;
      }
    },
    UPDATE_CURRENT_AFFILIATE(state, payload) {
      const index = state.affiliateExpTweets.findIndex((item) => {
        return item.userId === payload.userId;
      });
      state.currentAffiliate = payload;
      state.currentAffiliateIndex = index;
    },
    UPDATE_TOURNEY_TWEET_PENDING_LIST(state, payload) {
      const index = state.accountTourneys.findIndex((tourney) => {
        return tourney.docId === payload.tourneyDocId;
      });
      if (state.accountTourneys[index].pendingList) {
        state.accountTourneys[index].pendingList.push(payload.twitterUsername);
      } else if (!state.accountTourneys[index].pendingList) {
        state.accountTourneys[index].pendingList = [payload.twitterUsername];
      }
      if (
        state.accountTourneys[index] &&
        state.accountTourneys[index].pendingFullList
      ) {
        if (
          !state.accountTourneys[index].pendingFullList.some(
            (obj) => obj.twitterUsername === payload.twitterUsername
          )
        ) {
          state.accountTourneys[index].pendingFullList.push({
            twitterUsername: payload.twitterUsername,
            username: state.username,
            fortniteBuildsLevel: state.profileFortniteBuildsLevel || '',
            discordUsername: state.profileDiscordUsername,
            fortniteUsername: state.profileFortniteUsername,
            credits: false,
          });
        }
      } else if (!state.accountTourneys[index].pendingFullList) {
        state.accountTourneys[index].pendingFullList = [
          {
            twitterUsername: payload.twitterUsername,
            username: state.username,
            fortniteBuildsLevel: state.profileFortniteBuildsLevel || '',
            discordUsername: state.profileDiscordUsername,
            fortniteUsername: state.profileFortniteUsername,
            credits: false,
          },
        ];
      }
      state.accountTourneys[index].stepCompleted = true;
      state.accountTourneys[index].btnStatus = 'Pending';
      state.accountTourneys[index].stepStatus = 'Pending';
    },
    UPDATE_GIVEAWAY_TWEET_PENDING_LIST(state, payload) {
      const index = state.accountAllGiveaways.findIndex((giveaway) => {
        return giveaway.docId === payload.giveawayDocId;
      });
      if (state.accountAllGiveaways[index].pendingList) {
        state.accountAllGiveaways[index].pendingList.push(
          payload.twitterUsername
        );
      } else if (!state.accountAllGiveaways[index].pendingList) {
        state.accountAllGiveaways[index].pendingList = [
          payload.twitterUsername,
        ];
      }
      if (
        state.accountAllGiveaways[index] &&
        state.accountAllGiveaways[index].pendingFullList
      ) {
        if (
          !state.accountAllGiveaways[index].pendingFullList.some(
            (obj) => obj.twitterUsername === payload.twitterUsername
          )
        ) {
          state.accountAllGiveaways[index].pendingFullList.push({
            twitterUsername: payload.twitterUsername,
            username: state.username,
            fortniteBuildsLevel: state.profileFortniteBuildsLevel || '',
            discordUsername: state.profileDiscordUsername,
            fortniteUsername: state.profileFortniteUsername,
          });
        }
      } else if (!state.accountAllGiveaways[index].pendingFullList) {
        state.accountAllGiveaways[index].pendingFullList = [
          {
            twitterUsername: payload.twitterUsername,
            username: state.username,
            fortniteBuildsLevel: state.profileFortniteBuildsLevel || '',
            discordUsername: state.profileDiscordUsername,
            fortniteUsername: state.profileFortniteUsername,
          },
        ];
      }
      state.accountAllGiveaways[index].stepCompleted = true;
      state.accountAllGiveaways[index].btnStatus = 'Pending';
      state.accountAllGiveaways[index].stepStatus = 'Pending';
    },
    UPDATE_TWEETS_EXP_PENDING_LIST(state, payload) {
      const index = state.accountExpTweets.findIndex((item) => {
        return item.id === payload.tweetId;
      });
      if (state.accountExpTweets[index].pendingList) {
        state.accountExpTweets[index].pendingList.push(payload.twitterUsername);
      } else if (!state.accountExpTweets[index].pendingList) {
        state.accountExpTweets[index].pendingList = [payload.twitterUsername];
      }
      if (
        state.accountExpTweets[index] &&
        state.accountExpTweets[index].pendingFullList
      ) {
        if (
          !state.accountExpTweets[index].pendingFullList.some(
            (obj) => obj.twitterUsername === payload.twitterUsername
          )
        ) {
          state.accountExpTweets[index].pendingFullList.push({
            twitterUsername: payload.twitterUsername,
            username: state.username,
            fortniteBuildsLevel: state.profileFortniteBuildsLevel || '',
            discordUsername: state.profileDiscordUsername,
            fortniteUsername: state.profileFortniteUsername,
            credits: false,
          });
        }
      } else if (
        state.accountExpTweets[index] &&
        !state.accountExpTweets[index].pendingFullList
      ) {
        state.accountExpTweets[index].pendingFullList = [
          {
            twitterUsername: payload.twitterUsername,
            username: state.username,
            fortniteBuildsLevel: state.profileFortniteBuildsLevel || '',
            discordUsername: state.profileDiscordUsername,
            fortniteUsername: state.profileFortniteUsername,
            credits: false,
          },
        ];
      }
      state.accountExpTweets[index].stepCompleted = true;
      state.accountExpTweets[index].btnStatus = 'Pending';
      state.accountExpTweets[index].stepStatus = 'Pending';
      const indexExpList = state.accountExpList.findIndex((item) => {
        return item.username === state.username;
      });

      if (indexExpList === -1) {
        state.accountExpList.push(state.profileAccountExp);
      }
    },
    UPDATE_AFFILIATE_TWEETS_EXP_PENDING_LIST(state, payload) {
      const index = state.affiliateExpTweets.findIndex((affiliate) => {
        return affiliate.userId === payload.userId;
      });

      if (index !== -1) {
        let affiliateTweets =
          state.affiliateExpTweets[index].tweets[payload.index];
        if (
          state.affiliateExpTweets &&
          state.affiliateExpTweets[index] &&
          state.affiliateExpTweets[index].pendingCount
        ) {
          state.affiliateExpTweets[index].pendingCount =
            state.affiliateExpTweets[index].pendingCount + 1;
        } else {
          state.affiliateExpTweets[index].pendingCount = 0;
        }

        if (affiliateTweets.pendingList) {
          affiliateTweets.pendingList.push(payload.twitterUsername);
        } else if (!affiliateTweets.pendingList) {
          affiliateTweets.pendingList = [payload.twitterUsername];
        }

        if (
          state.affiliateExpTweets[index] &&
          state.affiliateExpTweets[index].pendingFullList
        ) {
          if (
            !state.affiliateExpTweets[index].pendingFullList.some(
              (obj) => obj.twitterUsername === payload.twitterUsername
            )
          ) {
            state.affiliateExpTweets[index].pendingFullList.push({
              twitterUsername: payload.twitterUsername,
              username: state.username,
              fortniteBuildsLevel: state.profileFortniteBuildsLevel || '',
              discordUsername: state.profileDiscordUsername,
              fortniteUsername: state.profileFortniteUsername,
              credits: false,
            });
          }
        } else if (
          state.affiliateExpTweets[index] &&
          !state.affiliateExpTweets[index].pendingFullList
        ) {
          state.affiliateExpTweets[index].pendingFullList = [
            {
              twitterUsername: payload.twitterUsername,
              username: state.username,
              fortniteBuildsLevel: state.profileFortniteBuildsLevel || '',
              discordUsername: state.profileDiscordUsername,
              fortniteUsername: state.profileFortniteUsername,
              credits: false,
            },
          ];
        }
        affiliateTweets.stepCompleted = true;
        affiliateTweets.btnStatus = 'Pending';
        affiliateTweets.stepStatus = 'Pending';
      }
    },

    UPDATE_TWEET_STEP(state, payload) {
      state.currentTweetStep = payload;
    },

    UPDATE_TWITTER_GIVEAWAY_LIKES_LIST(state, payload) {
      const index = state.accountAllGiveaways.findIndex((giveaway) => {
        return giveaway.docId === payload.docId;
      });
      state.accountAllGiveaways[index].tweetLikes = payload.tweetLikes;
    },

    UPDATE_TWITTER_EXP_LIST(state, payload) {
      state.accountExpTweets[payload.id].stepCompleted = true;
      state.accountExpTweets[payload.id].btnStatus = 'Pending';
      state.accountExpTweets[payload.id].stepStatus = 'Pending';
      if (state.accountExpTweets[payload.id].requests.length) {
        state.accountExpTweets[payload.id].requests.push(
          payload.twitterUsername
        );
      } else {
        state.accountExpTweets[payload.id].requests = [payload.twitterUsername];
      }
      const index = state.accountExpList.findIndex((item) => {
        return item.username === state.username;
      });
      if (index !== -1) {
        state.accountExpList[index] = state.profileAccountExp;
      } else if (index === -1) {
        state.accountExpList.push(state.profileAccountExp);
      }
    },

    UPDATE_AFFILIATE_TWITTER_EXP_LIST(state, payload) {
      state.affiliateExpTweets[payload.index].tweets[
        payload.id
      ].stepCompleted = true;
      state.affiliateExpTweets[payload.index].tweets[payload.id].btnStatus =
        'Pending';
      state.affiliateExpTweets[payload.index].tweets[payload.id].stepStatus =
        'Pending';

      if (
        state.affiliateExpTweets &&
        state.affiliateExpTweets[payload.index].tweets[payload.id].requests
          .length
      ) {
        state.affiliateExpTweets[payload.index].tweets[
          payload.id
        ].requests.push(payload.twitterUsername);
      } else {
        state.affiliateExpTweets[payload.index].tweets[payload.id].requests = [
          payload.twitterUsername,
        ];
      }
      const index = state.accountExpList.findIndex((item) => {
        return item.username === state.username;
      });
      if (index !== -1) {
        state.accountExpList[index] = state.profileAccountExp;
      } else if (index === -1) {
        state.accountExpList.push(state.profileAccountExp);
      }
    },
    UPDATE_TWITTER_TOURNEY_LIKES_LIST(state, payload) {
      const index = state.accountTourneys.findIndex((tourney) => {
        return tourney.docId === payload.docId;
      });
      state.accountTourneys[index].tweetLikes = payload.tweetLikes;
    },
    UPDATE_YOUTUBE_SHORTS(state, payload) {
      state.profileYoutubeShorts = payload;
    },
    UPDATE_YOUTUBE_VIDEOS(state, payload) {
      state.profileYoutubeVideos = payload;
    },
    UPDATE_YOUTUBE_VODS(state, payload) {
      state.profileYoutubeVods = payload;
    },

    SET_TEAM(state, payload) {
      state.team.push(payload);
    },
    SET_CREDITS_RANKINGS(state, payload) {
      state.creditsRankings.push(payload);
    },
    SET_COINS_RANKINGS(state, payload) {
      state.coinsRankings.push(payload);
    },
    REMOVE_FIRST_TOURNEY(state) {
      state.accountTourneys.shift();
    },

    SET_ACCOUNT_TOURNEY(state, payload) {
      const index = payload.tourney.list.findIndex((tourney) => {
        return tourney.docId === payload.docId;
      });

      state.accountTourney = payload.tourney.list[index];
    },

    SET_TOURNEY_PARTICIPANTS(state, payload) {
      state.accountTourneySelected = payload;
    },

    ADD_ACCOUNT_TWEETS_REQUESTS(state, payload) {
      state.accountTweetsRequests = [];
      state.accountTweetsRequests.push(payload.newRequest);
    },

    ADD_AFFILIATE_TWEETS_REQUESTS(state, payload) {
      state.affiliateTweetsRequests.push(payload);
    },

    UPDATE_ACCOUNT_TWEETS_REQUESTS(state, payload) {
      const index = state.accountTweetsRequests.findIndex((request) => {
        return request.tweetId === payload.tweetId;
      });
      if (payload.type === 'tourneys') {
        let newRequest = {
          twitterUsername: state.profileTwitterUsername,
          username: state.username,
          credits: false,
          participate: true,
          type: payload.type,
          discordUsername: state.profileDiscordUsername,
          fortniteUsername: state.profileFortniteUsername,
        };
        if (state.profileFortniteBuildsLevel) {
          newRequest.fortniteBuildsLevel = state.profileFortniteBuildsLevel;
        }
        state.accountTweetsRequests[index].requests.push(newRequest);
      } else if (payload.type === 'exp') {
        state.accountTweetsRequests[index].requests.push({
          twitterUsername: payload.twitterUsername,
          type: payload.type,
          username: state.username,
        });
      } else if (payload.type === 'giveaways') {
        state.accountTweetsRequests[index].requests.push({
          twitterUsername: payload.twitterUsername,
          type: payload.type,
          username: state.username,
        });
        if (state.accountSelectedRequestsTypes)
          state.accountSelectedRequestsTypes.push(payload.type);
      }
    },

    UPDATE_AFFILIATE_TWEETS_REQUESTS(state, payload) {
      const index = state.affiliateTweetsRequests.findIndex((request) => {
        return request.tweetId === payload.tweetId;
      });
      if (
        state.affiliateTweetsRequests[index].types &&
        !state.affiliateTweetsRequests[index].types.includes(payload.type)
      ) {
        state.affiliateTweetsRequests[index].types.push(payload.type);
      } else {
        state.affiliateTweetsRequests[index].types = [payload.type];
      }
      if (
        state.currentAffiliate.types &&
        !state.currentAffiliate.types.includes(payload.type)
      ) {
        state.currentAffiliate.types.push(payload.type);
      } else {
        state.currentAffiliate.types = [payload.type];
      }
      if (state.affiliateTweetsRequests[index].requests === '') {
        let usernamesList = [];
        usernamesList.push({
          twitterUsername: payload.twitterUsername,
          type: payload.type,
          username: state.username,
        });
        state.affiliateTweetsRequests[index].requests = usernamesList;
      } else {
        if (
          !state.affiliateTweetsRequests[index].requests.some(
            (obj) => obj.twitterUsername === payload.twitterUsername
          )
        )
          state.affiliateTweetsRequests[index].requests.push({
            twitterUsername: payload.twitterUsername,
            type: payload.type,
            username: state.username,
          });
      }
    },

    UPDATE_TOURNEY_STEP(state, payload) {
      const index = state.accountTourneys.findIndex((tourney) => {
        return tourney.docId === payload.docId;
      });
      state.accountTourneys[index].stepCompleted = payload.state;
    },

    UPDATE_GIVEAWAY_STEP(state, payload) {
      const index = state.accountAllGiveaways.findIndex((giveaway) => {
        return giveaway.docId === payload.docId;
      });
      state.accountAllGiveaways[index].stepCompleted = payload.state;
    },

    SET_PROFILE_TICKETS(state, payload) {
      state.currentNotifications = 0;
      state.currentUnreadMessages = 0;
      state.currentTicketProfile = payload.val();
      if (
        payload.val() &&
        payload.val().list &&
        payload.val().list[state.currentTicketIndex]
      ) {
        state.currentTicket = payload.val().list[state.currentTicketIndex];
        if (state.profileAdmin) {
          state.currentNotifications = state.tickets.length;
          if (payload.val().list[state.currentTicketIndex].unreadMessages) {
            state.currentUnreadMessages =
              payload.val().list[
                state.currentTicketIndex
              ].unreadMessages.client;
          }
        } else {
          if (state.currentTicket.assignedTo) {
            state.currentUnreadMessages =
              payload.val().list[state.currentTicketIndex].unreadMessages[
                state.currentTicket.assignedTo.username
              ]; // not sure its right
          }
          if (state.currentTicket.participants) {
            state.currentTicket.participants.forEach((participant) => {
              state.currentUnreadMessages =
                state.currentUnreadMessages +
                payload.val().list[0].unreadMessages[participant.username];
            });
          }
        }
      }

      state.currentNotifications =
        state.currentNotifications + state.currentUnreadMessages;
    },
    SET_TICKETS(state, payload) {
      state.tickets.push(payload);
      state.currentTicketProfile = payload;
      state.currentNotifications = state.tickets.length;
      // payload.list.forEach((ticket) => {
      //   if (ticket.unreadMessages && ticket.unreadMessages.client) {
      //     state.currentUnreadMessages =
      //       state.currentUnreadMessages + ticket.unreadMessages.client;
      //   }
      // });
    },
    UPDATE_CURRENT_TICKET(state, { ticket, i }) {
      state.currentTicket = ticket;
      state.currentTicketIndex = i;
    },
    CLOSE_RANKINGS_APPLICATION(state, payload) {
      let index = state.tickets.findIndex((application) => {
        return application.userId === state.currentTicketProfile.userId;
      });
      state.tickets.splice(index, 1);
      state.currentNotifications = state.currentNotifications - 1;
      state.currentTicketProfile = payload[0];
    },
    UPDATE_CURRENT_TICKET_PROFILE(state, payload) {
      state.currentTicketProfile = payload;
      state.currentTicket = payload.list[0];
    },
    UPDATE_TICKET_FORTNITE_LEVEL(state, payload) {
      state.currentTicket.fortniteBuildsLevel = payload;
    },
    REMOVE_CURRENT_TICKET(state, payload) {
      state.tickets.splice(payload, 1);
    },
    UPDATE_TICKET_MESSAGES(state, payload) {
      if (state.currentTicket.messages.length > 29) {
        state.currentTicketProfile.list[
          state.currentTicketIndex
        ].messages.splice(0, 1);
        state.currentTicketProfile.list[state.currentTicketIndex].messages.push(
          payload
        );
        state.currentTicket.messages.splice(0, 1);
        state.currentTicket.messages.push(payload);
      } else {
        state.currentTicketProfile.list[state.currentTicketIndex].messages.push(
          payload
        );
        state.currentTicket.messages.push(payload);
      }
    },
    RESET_TICKET_UNREAD_MESSAGES(state) {
      if (state.currentTicketProfile.username !== state.username) {
        state.currentNotifications =
          state.currentNotifications -
          state.currentTicketProfile.list[state.currentTicketIndex]
            .unreadMessages.client;
        state.currentTicketProfile.list[
          state.currentTicketIndex
        ].unreadMessages.client = 0;
      } else if (state.currentTicketProfile.username === state.username) {
        state.currentNotifications = 0;

        state.currentTicketProfile.list[
          state.currentTicketIndex
        ].unreadMessages[state.currentTicket.assignedTo.username] = 0;
        if (
          state.currentTicketProfile.list[state.currentTicketIndex].participants
        ) {
          state.currentTicket.participants.forEach((participant) => {
            state.currentTicketProfile.list[
              state.currentTicketIndex
            ].unreadMessages[participant.username] = 0;
          });
        }
      }
    },
    UPDATE_UNREAD_MESSAGES(state, payload) {
      if (state.currentTicketProfile.username !== state.username) {
        if (
          state.currentTicketProfile.list[state.currentTicketIndex]
            .unreadMessages[state.username]
        ) {
          state.currentTicketProfile.list[
            state.currentTicketIndex
          ].unreadMessages[state.username] =
            state.currentTicket.unreadMessages[state.username] + payload;
        } else {
          state.currentTicketProfile.list[
            state.currentTicketIndex
          ].unreadMessages[state.username] = 1;
        }
      } else {
        state.currentTicketProfile.list[
          state.currentTicketIndex
        ].unreadMessages.client =
          state.currentTicket.unreadMessages.client + payload;
      }
    },
    UPDATE_TICKET_ASSIGNED_TO(state, payload) {
      state.currentTicketProfile.list[state.currentTicketIndex].assignedTo =
        payload;
      state.currentTicket.assignedTo = payload;
    },
    UPDATE_TICKET_UNREAD_MESSAGES(state) {
      if (state.currentTicket.participants) {
        state.currentTicket.participants.push({
          username: state.username,
        });
      } else {
        state.currentTicket.participants = [{ username: state.username }];
      }
      state.currentTicketProfile.list[state.currentTicketIndex].unreadMessages =
        {
          participants: state.currentTicket.participants,
          [state.username]: 1,
          client: 0,
        };
    },
    SET_SWEATS_I(state, payload) {
      state.rankingsSweatI.push(payload);
    },
    SET_ACTIVE_GIVEAWAYS(state, payload) {
      state.allActiveGiveaways.push(payload);
      payload.list.forEach((item) => {
        state.allFortniteGiveaways.push(item);
      });
    },
    SET_ACCOUNT_WON_GIVEAWAYS(state, payload) {
      state.accountWonGiveaways.push(payload.data());
    },
    SET_ACCOUNT_WON_TOURNEYS(state, payload) {
      state.accountWonTourneys.push(payload.data());
    },
    UPDATE_ACCOUNT_GIVEAWAYS(state, payload) {
      state.accountAllGiveaways.push(payload);
    },
    UPDATE_ACCOUNT_TOURNEYS(state, payload) {
      state.accountTourneys.push(payload);
    },
    UPDATE_TOURNEY_RTDB_DOCID(state, payload) {
      const index = state.accountTourneys.findIndex((account) => {
        return account.docId === payload.docId;
      });
      state.accountTourneys[index].rtdbDocId = payload.rtdbDocId;
    },
    UPDATE_ADMIN_TOURNEYS(state, payload) {
      state.accountTourneys.push(payload.newTourney);
    },
    UPDATE_PROFILE_TAB(state, payload) {
      state.profileTab = payload;
    },

    SET_ARTICLES(state, payload) {
      state.articles.push(payload);
    },
    SET_ARTICLE(state, payload) {
      state.article = payload.data();
    },
    UPDATE_PROFILE_MODE(state, payload) {
      state.profileMode = payload;
    },
    SET_VLIVE_SCHEDULE(state, payload) {
      state.events = state.events.concat(payload);
    },
    SET_STREAMER_EXP(state, payload) {
      state.streamerXpList = payload.data().expList;
    },

    UPDATE_STEPS_TIME(state, payload) {
      state.profileStepsTime = payload;
    },

    SET_STORE(state, payload) {
      state.store.push(payload);
    },
    SET_CURRENT_WEEKS(state) {
      var currentDate = moment();
      var weekStart = currentDate.clone().startOf('isoWeek');
      let weeks = [];
      for (var i = 0; i < 7; i++) {
        weeks.push({
          weekday: moment(weekStart).add(i, 'days').format('dddd'),
          date: moment(weekStart).add(i, 'days').format('YYYY-MM-DD'),
          not_available: false,
        });
      }
      state.currentWeeks = weeks;
    },

    SET_AVAILABLE_WEEKDAYS(state, payload) {
      state.availableWeekdays.push(payload);
    },

    SET_EMOTES(state, payload) {
      state.emotes.push(payload);
      if (payload.category === 'Follower') {
        state.followerEmotes.push(payload);
      } else if (payload.category === 'Tier I') {
        state.tierIEmotes.push(payload);
      } else if (payload.category === 'Tier II') {
        state.tierIIEmotes.push(payload);
      } else if (payload.category === 'Tier III') {
        state.tierIIIEmotes.push(payload);
      } else if (payload.category === 'Bits') {
        state.bitsEmotes.push(payload);
      } else if (payload.category === 'Xmas') {
        state.xmasEmotes.push(payload);
      }
    },

    SET_SCHEDULE_EVENTS(state, payload) {
      state.events.push(payload);
    },

    SET_TODAY_SCHEDULE_EVENTS(state, payload) {
      state.todayEvents.push(payload);
      payload.hours.forEach((hour) => {
        if (state.eventsHours.includes(hour)) {
          let hourIndex = state.eventsHours.indexOf(hour);
          if (hourIndex > -1) {
            state.eventsHours.splice(hourIndex, 1);
          }
        }
      });
    },

    SET_USERS_LEVELS(state, payload) {
      state.usersLevels.push(payload);
    },

    SET_ONLINE_ACCOUNTS(state, payload) {
      state.onlineAccounts.push(payload);
    },

    SET_ACCOUNT_VIEWERS(state, payload) {
      state.accountViewers.push(payload);
    },

    SET_ACCOUNT_VIEWERS_COUNT(state) {
      state.accountViewersCount = state.accountViewers.length;
    },

    UPDATE_ACCOUNT_VIEWERS(state, payload) {
      state.accountViewers.splice(1, payload);
      state.accountViewersCount = state.accountViewers.length;
    },

    SET_UPDATED_STATE(state, payload) {
      const index = state.accountsState.findIndex((account) => {
        return account.userId === payload.userId;
      });
      state.accountsState[index].state = payload.state;
    },

    SET_UPDATED_ACCOUNT(state, payload) {
      const index = state.accounts.findIndex((account) => {
        return account.userId === payload.userId;
      });
      state.accounts[index] = payload;
    },

    SET_UPDATED_EVENT(state, payload) {
      const index = state.events.findIndex((event) => {
        return event.docId === payload.docId;
      });
      state.events[index] = payload;
    },

    UPDATE_GIVEAWAYS_LIST(state, payload) {
      const index = payload.giveaway.participants.findIndex((event) => {
        return event.name === payload.winner;
      });
      state.profileCurrentParticipants[index] = {
        id: index,
        name: payload.winner,
        participate: false,
      };
      let giveaway = state.accountAllGiveaways[payload.giveaway.id];
      giveaway.participants[index] = {
        id: index,
        name: payload.winner,
        participate: false,
      };
      giveaway.winners.push(payload.winner);
    },

    UPDATE_GIVEAWAY_WINNERS(state, payload) {
      const index = state.accountAllGiveaways.findIndex((event) => {
        return event.docId === payload.docId;
      });
      if (index !== -1) {
        state.accountAllGiveaways[index].active = false;
        state.accountAllGiveaways[index].winnersPrizes = payload.winnersPrizes;
      }
    },

    REMOVE_AFFILIATE(state, payload) {
      const index = state.affiliatesExpTweets.findIndex((profile) => {
        return profile.userId === payload;
      });
      if (index !== -1) {
        state.affiliatesExpTweets.splice(index, 1);
      } else {
        return;
      }
    },

    UPDATE_APPLICATION_STATUS(state, payload) {
      const index = payload.giveaway.applications.findIndex((application) => {
        return application.username === payload.application.username;
      });
      let updatedApplication = {};
      if (payload.status === 'Approved') {
        updatedApplication = {
          ...payload.application,
          status: 'Approved',
        };
        state.accountAllGiveaways[payload.giveaway.id].applications[
          index
        ].status = 'Approved';
        state.accountAllGiveaways[payload.giveaway.id].participants.push(
          updatedApplication
        );
      } else if (payload.status === 'Rejected') {
        updatedApplication = {
          ...payload.application,
          status: 'Rejected',
          rejectionReason: payload.rejectionReason,
        };
        state.accountAllGiveaways[payload.giveaway.id].applications[
          index
        ].status = 'Rejected';
        state.accountAllGiveaways[payload.giveaway.id].rejected.push(
          updatedApplication
        );
      }
    },

    UPDATE_GIVEAWAY_STATUS(state, payload) {
      state.accountAllGiveaways = [payload];
    },

    UPDATE_CURRENT_GIVEAWAY(state, payload) {
      state.profileCurrentGiveaway = payload;
      state.profileCurrentParticipants = payload.participants;
    },

    UPDATE_CURRENT_PARTICIPANTS_GIVEAWAY(state, payload) {
      let participants = [];
      payload.forEach((item) => {
        if (item.participate) {
          participants.push(item);
        }
      });
      let numSlices = participants.length;
      let probabilityTest = Math.floor(100 / numSlices);
      let remainder = 100 - probabilityTest * numSlices;
      let probabilityArray = Array(numSlices).fill(probabilityTest);
      for (let i = 0; i < remainder; i++) {
        let slice = Math.floor(Math.random() * numSlices);
        probabilityArray[slice] += 1;
      }
      let colors = ['red', 'brown', 'green', 'purple', 'blue', 'orange'];
      participants.forEach((item, index) => {
        item.probability = probabilityArray[index];
        item.color = 'white';
        item.bgColor = colors[Math.floor(Math.random() * 6) + 1];
        item.fontSize = 10;
      });
      state.profileCurrentParticipants = participants;
    },

    UPDATE_GIVEAWAY_PARTICIPANTS(state, payload) {
      const index = state.accountAllGiveaways.findIndex((giveaway) => {
        return giveaway.docId === payload.docId;
      });
      let newParticipant = {
        username: state.username,
        fortniteUsername: state.profileFortniteUsername,
        twitterUsername: state.profileTwitterUsername,
        discordUsername: state.profileDiscordUsername,
        participate: true,
      };
      if (state.profileFortniteBuildsLevel) {
        newParticipant.gameLevel = state.profileFortniteBuildsLevel;
      }
      state.accountAllGiveaways[index].participants.push(newParticipant);
      state.accountAllGiveaways[index].disabled = true;
      state.accountAllGiveaways[index].participate = true;
    },

    UPDATE_TOURNEY_PLAYER_WINNER(state) {
      if (state.rtdbSelectedCoinsWinners.length) {
        const index = state.rtdbSelectedCoinsWinners.findIndex((winner) => {
          return winner.username === state.username;
        });
        state.rtdbSelectedCoinsWinners[index].usernameClaimed = true;
      } else if (state.rtdbSelectedPrizesWinners.length) {
        const index = state.rtdbSelectedPrizesWinners.findIndex((winner) => {
          return winner.username === state.username;
        });
        state.rtdbSelectedPrizesWinners[index].usernameClaimed = true;
      }
    },

    UPDATE_TOURNEY_PLAYER_PRIZES_WINNER(state) {
      const index = state.rtdbSelectedPrizesWinners.findIndex((winner) => {
        return winner.username === state.username;
      });
      state.rtdbSelectedPrizesWinners[index].usernameClaimed = true;
    },

    UPDATE_TOURNEY_SPONSOR_WINNER(state) {
      const index = state.rtdbSelectedCoinsWinners.findIndex((winner) => {
        if (!winner.sponsorClaimed) {
          return winner.sponsorUsername === state.username;
        }
      });
      state.rtdbSelectedCoinsWinners[index].sponsorClaimed = true;
    },

    UPDATE_GIVEAWAY_CLAIM_WINNERS(state) {
      const index = state.rtdbSelectedGiveawaysWinners.findIndex((winner) => {
        return winner.username === state.username;
      });
      state.rtdbSelectedGiveawaysWinners[index].usernameClaimed = true;
    },

    UPDATE_TOURNEY_PARTICIPANTS(state, payload) {
      const indexTourneys = state.accountTourneys.findIndex((tourney) => {
        return tourney.docId === payload.tourney.docId;
      });

      let newParticipant = {
        username: state.username,
        gameLevel: state.profileFortniteBuildsLevel,
        discordUsername: state.profileDiscordUsername,
        participate: true,
      };

      state.accountTourneys[indexTourneys].participants.push(newParticipant);
      state.accountTourneys[indexTourneys].disabled = true;
    },

    UPDATE_BOOST_TOURNEY(state, payload) {
      let boosts = [];
      let newBoost = {
        username: state.username,
        discordUsername: state.profileDiscordUsername,
        twitterUsername: state.profileTwitterUsername,
        boostRank: payload,
      };
      if (
        state.rtdbAllBettings[payload] &&
        state.rtdbAllBettings[payload].length
      ) {
        state.rtdbAllBettings[payload].push(newBoost);
      } else {
        boosts.push(newBoost);
        state.rtdbAllBettings[payload] = boosts;
      }
    },

    UPDATE_TOURNEY_COINS_PARTICIPANTS(state, payload) {
      const index = payload.participants.findIndex((participant) => {
        return participant.username === state.username;
      });

      const indexTourneys = state.accountTourneys.findIndex((tourney) => {
        return tourney.docId === payload.docId;
      });
      let newParticipant = {
        username: state.username,
        discordUsername: state.profileDiscordUsername,
        gameLevel: '',
        twitterUsername: state.profileTwitterUsername,
        fortniteUsername: state.profileFortniteUsername,
        participate: true,
        credits: true,
      };
      if (state.profileFortniteBuildsLevel) {
        newParticipant.gameLevel = state.profileFortniteBuildsLevel;
      }
      if (index !== -1) {
        state.accountTourneys[indexTourneys].participants[index].credits = true;
        state.accountTourneys[indexTourneys].coinsParticipants.push(
          newParticipant
        );
      } else {
        if (state.profileFortniteBuildsLevel) {
          newParticipant.gameLevel = state.profileFortniteBuildsLevel;
        }
        state.accountTourneys[indexTourneys].coinsParticipants.push(
          newParticipant
        );
        state.accountTourneys[indexTourneys].participants.push(newParticipant);
      }
    },

    UPDATE_TOURNEY_LIST(state, payload) {
      const index = state.accountTourneys.findIndex((tourney) => {
        return tourney.docId === payload.docId;
      });
      let combinedArray = [];
      if (payload.winnersCoins && payload.winnersCoins.length) {
        state.accountTourneys[index].winnersCoins = payload.winnersCoins;
        combinedArray = [
          ...new Set(payload.winnersPrizes.concat(payload.winnersCoins)),
        ];
      } else {
        combinedArray = payload.winnersPrizes;
      }
      const usernames = combinedArray.map((user) => user.username);
      state.accountTourneys[index].usernames = usernames;
      if (payload.winnersPrizes && payload.winnersPrizes.length) {
        state.accountTourneys[index].winnersPrizes = payload.winnersPrizes;
      }

      state.accountTourneys[index].active = false;
    },

    UPDATE_TOURNEY_PRIZES_PARTICIPANTS(state, payload) {
      const index = state.accountTourneys.findIndex((tourney) => {
        return tourney.docId === payload.tourney.docId;
      });

      if (
        !state.accountTourneys[index].participants.some(
          (obj) => obj.username === state.username
        )
      ) {
        state.accountTourneys[index].participant = true;
        let newParticipant = {
          username: state.username,
          discordUsername: state.profileDiscordUsername,
          fortniteUsername: state.profileFortniteUsername,
          gameLevel: '',
          participate: true,
          credits: false,
        };
        if (state.profileFortniteBuildsLevel) {
          newParticipant.gameLevel = state.profileGameLevel;
        }
        state.accountTourneys[index].participants.push(newParticipant);
      }
    },

    UPDATE_TOURNEY_CANDIDATE_SPONSORED(state, payload) {
      const index = state.rtdbAllBettings.candidates.findIndex((candidate) => {
        return candidate.username === payload.username;
      });
      state.rtdbAllBettings.candidates[index].status = false;

      // state.rtdbAllBettings.candidates[index].sponsorPhoto =
      //   payload.sponsorPhoto;

      // state.rtdbAllBettings.candidates[index].sponsorshipsReceived =
      //   payload.sponsorshipsReceived + 1;
    },

    REMOVE_CANDIDATE_SPONSORSHIPS(state, payload) {
      state.rtdbAllBettings.candidates.splice(payload, 1);
    },

    UPDATE_CANDIDACY_STATUS(state) {
      const index = state.rtdbAllBettings.candidates.findIndex((candidate) => {
        return candidate.username === state.username;
      });
      if (index !== -1) {
        state.rtdbAllBettings.candidates[index].status = false;
      }
    },

    REMOVE_SPONSORSHIP(state, payload) {
      const index = state.rtdbAllBettings.sponsors.findIndex((candidate) => {
        return candidate.username === payload;
      });
      if (index !== -1) {
        state.rtdbAllBettings.sponsors[index].status = false;
      }
      const indexCandidate = state.rtdbAllBettings.candidates.findIndex(
        (candidate) => {
          return candidate.username === payload;
        }
      );
      if (index !== -1) {
        state.rtdbAllBettings.candidates[indexCandidate].sponsored = false;
      }
    },

    UPDATE_TOURNEY_SPONSORS(state, payload) {
      let sponsors = [];
      if (state.rtdbAllBettings && state.rtdbAllBettings.sponsors.length) {
        const indexSponsors = state.rtdbAllBettings.sponsors.findIndex(
          (sponsorship) => {
            return sponsorship.username === payload.username;
          }
        );
        if (indexSponsors !== -1) {
          state.rtdbAllBettings.sponsors[indexSponsors].status = true;
          state.rtdbAllBettings.sponsors[indexSponsors].sponsorUsername =
            payload.sponsor.sponsorUsername;
          state.rtdbAllBettings.sponsors[indexSponsors].gameLevel =
            payload.sponsor.fortniteBuildsLevel;
        } else if (indexSponsors === -1) {
          state.rtdbAllBettings.sponsors.push(payload.sponsor);
        }
      } else if (
        state.rtdbAllBettings &&
        !state.rtdbAllBettings.sponsors.length
      ) {
        sponsors.push(payload.sponsor);
        state.rtdbAllBettings.sponsors = sponsors;
      }
    },
    UPDATE_TOURNEY_CANDIDATES(state, payload) {
      let candidates = [];
      if (state.rtdbAllBettings && state.rtdbAllBettings.candidates.length) {
        const index = state.rtdbAllBettings.candidates.findIndex(
          (candidate) => {
            return candidate.username === state.username;
          }
        );
        if (index !== -1) {
          state.rtdbAllBettings.candidates[index].status = true;
          state.rtdbAllBettings.candidates[index].gameLevel =
            state.profilegameLevel;
        } else if (index === -1) {
          if (
            state.rtdbAllBettings.candidates &&
            state.rtdbAllBettings.candidates.length
          ) {
            state.rtdbAllBettings.candidates.push(payload);
          } else {
            let candidateList = [];
            candidateList.push(payload);
            state.rtdbAllBettings.candidates = candidateList;
          }
        }
      } else if (
        state.rtdbAllBettings &&
        !state.rtdbAllBettings.candidates.length
      ) {
        candidates.push(payload);
        state.rtdbAllBettings.candidates = candidates;
      }
    },
    UPDATE_ACTIVE_TOURNEY_ID(state, payload) {
      state.accountTourneys[payload.tourneyId].docId = payload.docId;
    },
    ADD_TOURNEY_APPLICATIONS(state, payload) {
      state.accountTourneyApplications.push(payload);
      state.profilegameLevelStatus = true;
    },

    UPDATE_TOURNEY_APPLICATIONS(state, payload) {
      state.accountTourneyApplications[payload.id] = payload.item;
    },

    SET_UPDATED_ONLINE_LIST(state, payload) {
      const index = state.onlineAccounts.findIndex((account) => {
        return account.day === payload.userId;
      });
      state.onlineAccounts[index] = payload;
    },

    SET_EMOTE_PHOTO(state, payload) {
      state.emotePhoto = payload;
    },

    SET_LIVE_STREAMER(state, payload) {
      state.liveStreamer = payload.val();
    },

    TEAM_LOADED(state) {
      state.teamLoaded = true;
    },
    COINS_RANKINGS_LOADED(state) {
      state.creditsRankingsLoaded = true;
    },

    PROFILE_RANKS_LOADED(state) {
      state.profileRanksLoaded = true;
    },

    EXP_LOADED(state) {
      state.expLoaded = true;
    },

    ONLINE_ACCOUNTS_LOADED(state) {
      state.onlineLoaded = true;
    },
    ACCOUNT_VIEWERS_LOADED(state) {
      state.viewersLoaded = true;
    },

    STREAMER_LOADED(state) {
      state.streamerLoaded = true;
    },

    RTDB_GIVEAWAY_LOADED(state) {
      state.rtdbGiveawayLoaded = true;
    },

    TICKETS_LOADED(state) {
      state.ticketsLoaded = true;
    },
    PROFILE_TICKETS_LOADED(state) {
      state.profileTicketsLoaded = true;
    },

    AFFILIATES_EXP_LOADED(state) {
      state.affiliatesExpLoaded = true;
    },

    ROUTE_LOADED(state, payload) {
      state.routeLoaded = payload;
    },
  },

  actions: {
    async CHECK_USERNAME({ state }, username) {
      let finalUsername = username.toLowerCase();
      let nonUsernames = [
        'signup',
        'login',
        'store',
        'dashboard',
        'profile',
        'vpass',
        'settings',
        'article',
        'articles',
        'shop',
        'giveaways',
        'verify-email',
        'ranks',
        'customs',
        'vlive',
        'feed',
        'clips',
      ];
      if (
        finalUsername &&
        !nonUsernames.some((item) => item === finalUsername)
      ) {
        state.nonUsername = true;
        const userNameDoc = await db
          .collection('accounts')
          .where('username', '==', finalUsername)
          .get();

        if (!userNameDoc.empty) {
          state.validUsername = false;
        } else {
          state.validUsername = true;
        }
      } else {
        state.nonUsername = false;
      }
    },
    async CHECK_EMAIL({ state }, email) {
      if (email) {
        const userNameDoc = await db
          .collection('accounts')
          .where('email', '==', email)
          .get();
        if (!userNameDoc.empty) {
          state.checkEmail = false;
        } else {
          state.checkEmail = true;
        }
      }
    },
    async GET_TEAM({ commit, state }) {
      const dataBase = await db
        .collection('accounts')
        .where('admin', '==', true);
      const dbResults = await dataBase.get();
      const accounts = dbResults;

      accounts.forEach((doc) => {
        if (
          !state.team.some((member) => member.userId === doc.data().userId) &&
          doc.data().userId
        ) {
          const data = {
            userId: doc.data().userId,
            username: doc.data().username,
            email: doc.data().email,
            adminLevel: doc.data().adminLevel,
          };
          commit('SET_TEAM', data);
        }
      });
      commit('TEAM_LOADED');
    },
    async GET_COINS_RANKINGS({ commit, state }) {
      try {
        const snapshot = await rtdb
          .ref('accounts')
          .orderByChild('credits')
          .startAt(1) // Assuming credits are greater than 0
          .limitToLast(11)
          .once('value');

        const coinsRankings = snapshot.val();

        if (coinsRankings) {
          Object.values(coinsRankings).forEach((data) => {
            if (
              !state.coinsRankings.some(
                (item) => item.userId === data.userId
              ) &&
              data.userId
            ) {
              if (data.username !== 'vforce') {
                const newData = {
                  userId: data.userId,
                  username: data.username,
                  credits: data.credits,
                };
                commit('SET_COINS_RANKINGS', newData);
              }
            }
          });
        }

        commit('COINS_RANKINGS_LOADED');
      } catch (error) {
        console.error('Error getting credits rankings:', error);
        // Handle error
      }
    },
    async GET_CREDITS_RANKINGS({ commit, state }) {
      try {
        const snapshot = await rtdb
          .ref('accounts')
          .orderByChild('credits')
          .startAt(1) // Assuming credits are greater than 0
          .limitToLast(11)
          .once('value');

        const creditRankings = snapshot.val();

        if (creditRankings) {
          Object.values(creditRankings).forEach((data) => {
            if (
              !state.creditsRankings.some(
                (item) => item.userId === data.userId
              ) &&
              data.userId
            ) {
              if (data.username !== 'vforce') {
                const newData = {
                  userId: data.userId,
                  username: data.username,
                  credits: data.credits,
                };
                commit('SET_CREDITS_RANKINGS', newData);
              }
            }
          });
        }

        commit('COINS_RANKINGS_LOADED');
      } catch (error) {
        console.error('Error getting credits rankings:', error);
        // Handle error
      }
    },

    async GET_PROFILE_RANKS({ commit }, userId) {
      const dataBase = await db.collection('ranksFn').doc(userId);
      const dbResults = await dataBase.get();
      commit('SET_PROFILE_RANKS', dbResults);
      commit('PROFILE_RANKS_LOADED');
    },
    async TWITTER_LOGIN({ state }) {
      fb.auth()
        .currentUser.linkWithPopup(twitterProvider)
        .then((result) => {
          const accessToken = result.credential.accessToken;
          const accessTokenSecret = result.credential.secret;
          var twitterUsername = result.additionalUserInfo.username;
          var twitterUid = result.additionalUserInfo.profile.id;
          state.profileTwitterUsername = twitterUsername;
          state.profileTwitterStatus = true;
          db.collection('accounts').doc(state.userId).update({
            userId: state.userId,
            twitterUsername: twitterUsername,
            twitterUid: twitterUid,
            twitterStatus: true,
            twitterAccessToken: accessToken,
            twitterAccessSecretToken: accessTokenSecret, // Save the Twitter access token
          });
          db.collection('exp').doc(state.userId).update({
            userId: state.userId,
            twitterUsername: twitterUsername,
          });
        })
        .catch((error) => {
          state.profileTwitterErrorMsg = error.message;
        });
    },
    async GET_ACTIVE_GIVEAWAYS({ commit, state }) {
      const dataBase = await db
        .collection('giveaways')
        .where('active', '==', true);
      const dbResults = await dataBase.get();
      const giveaways = dbResults;
      if (!giveaways.empty) {
        giveaways.forEach((doc) => {
          if (
            !state.allActiveGiveaways.some(
              (giveaway) => giveaway.userId === doc.data().userId
            )
          ) {
            const list = doc.data().list.filter((item) => item.visibility);
            const data = {
              ...doc.data(),
              list: list,
            };
            commit('SET_ACTIVE_GIVEAWAYS', data);
          }
        });
      }
    },
    async GET_RTDB_GIVEAWAY({ commit }, docId) {
      let getData = await rtdb.ref('/giveaways/' + docId);
      getData.on('value', function (snapshot) {
        let data = snapshot;
        commit('SET_RTDB_GIVEAWAY', data);
      });
      commit('RTDB_GIVEAWAY_LOADED');
    },

    async GET_DIAMOND_III({ commit, state }) {
      state.rankingsSweatI = [];
      const dataBase = await db
        .collection('ranks')
        .where('fortniteBuildsLevel', '==', 'Diamond III');
      const dbResults = await dataBase.get();
      const sweatI = dbResults;
      sweatI.forEach((doc) => {
        if (
          !state.rankingsSweatI.some(
            (rank) => rank.userId === doc.data().userId
          )
        ) {
          const data = {
            userId: doc.data().userId,
            discordUsername: doc.data().discordUsername,
            fortniteUsername: doc.data().fortniteUsername,
            username: doc.data().username,
            fortniteBuildsLevel: doc.data().fortniteBuildsLevel,
          };
          commit('SET_SWEATS_I', data);
        }
      });
    },
    async GET_PROFILE_TICKETS({ commit }) {
      let getData = await rtdb.ref('/tickets/' + fb.auth().currentUser.uid);
      getData.on('value', function (snapshot) {
        let data = snapshot;
        commit('SET_PROFILE_TICKETS', data);
      });
      commit('PROFILE_TICKETS_LOADED');
    },
    async GET_CURRENT_MESSAGES({ commit, state }) {
      let newHash = state.routeHash.slice(1);
      let getData = await rtdb.ref('/tickets/' + newHash);
      getData.on('value', function (snapshot) {
        let data = snapshot;
        commit('SET_PROFILE_TICKETS', data);
      });
    },
    async GET_TICKETS({ commit, state }) {
      let getData = rtdb.ref('/tickets/').orderByChild('status').equalTo(true);
      await getData.on('value', function (snapshot) {
        snapshot.forEach(function (childSnapshot) {
          if (
            !state.tickets.some(
              (ticket) => ticket.userId === childSnapshot.val().userId
            )
          ) {
            commit('SET_TICKETS', childSnapshot.val());
          }
        });
      });
      commit('TICKETS_LOADED');
    },

    // async GET_PROFILE_EXP({ commit }) {
    //   let getData = await rtdb.ref("/exp/" + fb.auth().currentUser.uid);
    //   getData.on("value", function (snapshot) {
    //     let data = snapshot;
    //     commit("SET_PROFILE_EXP", data);
    //   });
    //   commit("EXP_LOADED");
    // },
    async START_COUNTER({ commit }) {
      commit('setCounter');
    },
    // async GET_LIVE_STREAMER_EXP({ commit, state }, docId) {
    //   const dataBase = await db.collection("exp").doc(docId);
    //   const dbResults = await dataBase.get();
    //   const streamerXp = dbResults;
    //   const streamerXpList = dbResults.data().viewerList;
    //   if (
    //     !streamerXpList.some(
    //       (streamer) => streamer.username === state.username
    //     )
    //   ) {
    //     let newViewer = {
    //       username: state.username,
    //       viewerExp: 0,
    //     };
    //     streamerXpList.push(newViewer);
    //     db.collection("exp")
    //       .doc(state.liveStreamer.userId)
    //       .update({ xpList: streamerXpList });
    //     commit("SET_STREAMER_EXP", streamerXpList);
    //   } else {
    //     commit("SET_STREAMER_EXP", streamerXp);
    //   }
    // },
    async GET_PROFILE_MERCH({ commit, state }) {
      const dataBase = await db.collection('merch').doc(state.userId);
      const dbResults = await dataBase.get();
      const merch = dbResults;
      if (merch) {
        commit('SET_PROFILE_MERCH', merch);
      }
    },
    async GET_ADVERTISING_SPOTS({ commit, state }, options) {
      const dataBase = await db
        .collection(options.advertisingType)
        .where('username', '==', options.username);
      const dbResults = await dataBase.get();
      const advertisingSpots = dbResults;
      advertisingSpots.forEach((doc) => {
        if (
          !state.advertisingSpots.some(
            (spot) => spot.docId === doc.data().docId
          ) &&
          doc.data().userId
        ) {
          const data = {
            userId: doc.data().userId,
            docId: doc.data().docId,
            title: doc.data().title,
            spotLink: doc.data().classLink,
            spotPhoto: doc.data().classPhoto,
            username: doc.data().username,
          };
          commit('SET_ADVERTISING_SPOTS', data);
        }
        // else if (
        //   state.advertisingSpots.some(
        //     (spot) =>
        //       spot.userId === doc.data().userId && !_.isEqual(spot, doc.data())
        //   )
        // ) {
        //   commit("SET_UPDATED_ADVERTISING_SPOTS", doc.data());
        // }
      });
    },
    async GET_STORE({ commit, state }) {
      const dataBase = await db.collection('store');
      const dbResults = await dataBase.get();
      const store = dbResults;
      store.forEach((doc) => {
        if (
          !state.store.some((merch) => merch.docId === doc.data().docId) &&
          doc.data().docId
        ) {
          const data = {
            username: doc.data().username,
            price: doc.data().price,
            category: doc.data().category,
            description: doc.data().description,
            docId: doc.data().docId,
            title: doc.data().title,
            reviews: doc.data().reviews,
            reviewsNr: doc.data().reviewsNr,
            link: doc.data().link,
            merchPhoto: doc.data().merchPhoto,
            tags: doc.data().tags,
          };
          commit('SET_STORE', data);
        }
      });
    },
    async GET_EMOTES({ commit, state }) {
      const dataBase = await db.collection('emotes');
      const dbResults = await dataBase.get();
      const emotes = dbResults;
      emotes.forEach((doc) => {
        if (
          !state.emotes.some((emote) => emote.docId === doc.data().docId) &&
          doc.data().docId
        ) {
          const data = {
            emotePhoto: doc.data().emotePhoto,
            category: doc.data().category,
            docId: doc.data().docId,
            amount: doc.data().amount,
          };
          commit('SET_EMOTES', data);
        }
      });
    },
    async GET_SCHEDULE_EVENTS({ commit, state }) {
      const dataBase = await db
        .collection('accounts')
        .where('schedule', '==', 'Champions')
        .orderBy('start');
      const dbResults = await dataBase.get();
      const events = dbResults;
      events.forEach((doc) => {
        if (!state.events.some((event) => event.docId === doc.data().docId)) {
          let start = doc.data().start;
          const replacedStart =
            start.substring(0, 10) + ' ' + start.substring(10 + 1);
          let finalStart = moment
            .utc(replacedStart)
            .local()
            .format('YYYY-MM-DD-HH:mm');
          let end = doc.data().end;
          const replacedEnd =
            end.substring(0, 10) + ' ' + end.substring(10 + 1);
          let finalEnd = moment
            .utc(replacedEnd)
            .local()
            .format('YYYY-MM-DD-HH:mm');

          const data = {
            userId: doc.data().userId,
            username: doc.data().username,
            profileInitials: doc.data().profileInitials,
            userPhotoUrl: doc.data().userPhotoUrl,
            followers: doc.data().followers,
            name: doc.data().name,
            details: doc.data().details,
            color: doc.data().color,
            rank: doc.data().rank,
            start: finalStart,
            end: finalEnd,
            docId: doc.data().docId,
            not_available: doc.data().not_available,
            socials: doc.data().socials,
            week: doc.data().week,
            startHour: doc.data().startHour,
            weekday: doc.data().weekday,
            created_at: doc.data().created_at,
          };
          commit('SET_SCHEDULE_EVENTS', data);
        }
      });
      commit('SET_CURRENT_WEEKS');
    },
    async GET_TODAY_SCHEDULE_EVENTS({ commit, state }) {
      let today = moment().format('YYYY-MM-DD');
      const dataBase = await db
        .collection('schedule')
        .where('created_at', 'array-contains', today)
        .orderBy('start');
      const dbResults = await dataBase.get();
      const events = dbResults;
      events.forEach((doc) => {
        if (
          !state.todayEvents.some((event) => event.docId === doc.data().docId)
        ) {
          let start = doc.data().created_at[0] + ' ' + doc.data().startHour[0];
          let finalStart = moment.utc(start).local().format('HH');
          let middle,
            finalMiddle = null;
          if (doc.data().startHour[1] !== '') {
            middle = doc.data().created_at[0] + ' ' + doc.data().startHour[1];
            finalMiddle = moment.utc(middle).local().format('HH');
          } else if (doc.data().startHour[1] === '') {
            finalMiddle = finalStart;
          }
          const data = {
            userId: doc.data().userId,
            username: doc.data().username,
            profileInitials: doc.data().profileInitials,
            userPhotoUrl: doc.data().userPhotoUrl,
            followers: doc.data().followers,
            name: doc.data().name,
            details: doc.data().details,
            color: doc.data().color,
            rank: doc.data().rank,
            start: doc.data().start,
            end: doc.data().end,
            docId: doc.data().docId,
            hours: [+finalStart, +finalMiddle],
            socials: doc.data().socials,
            startHour: doc.data().startHour,
            endHour: doc.data().endHour,
          };
          commit('SET_TODAY_SCHEDULE_EVENTS', data);
        }
      });
      commit('SCHEDULE_LOADED');
    },
    async GET_SCHEDULED_MERCH({ commit, state }) {
      const dataBase = await db.collection('merch');
      const dbResults = await dataBase.get();
      const merch = dbResults;
      merch.forEach((doc) => {
        if (
          !state.allScheduledMerch.some(
            (event) => event.userId === doc.data().userId
          )
        ) {
          const data = {
            userId: doc.data().userId,
            collection: doc.data().collections[0].items,
            username: doc.data().username,
            collectionName: doc.data().collections[0].name,
          };
          commit('SET_SCHEDULE_MERCH', data);
        }
      });
      // commit("SCHEDULE_LOADED");
    },
    async GET_STREAMERS_SCHEDULE({ commit }) {
      const dataBase = await db.collection('schedule').doc('00streamers');
      const dbResults = await dataBase.get();
      commit('SET_STREAMERS_SCHEDULE', dbResults);
    },
    async UPLOAD_PHOTO({ commit, state }, options) {
      let file = options.photo;
      let storageRef = fb.storage().ref('/' + options.folder + '/' + file.name);
      let uploadTask = storageRef.put(file);
      uploadTask.on(
        'state_changed',
        // eslint-disable-next-line
        (snapshot) => {},
        // eslint-disable-next-line
        (error) => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            if (options.destination === 'profile') {
              commit('SET_PROFILE_PHOTO', downloadURL);
              let account = {
                userPhotoUrl: downloadURL,
                userId: state.userId,
              };
              db.collection('accounts').doc(state.userId).update(account);
            } else if (options.destination === 'emote') {
              commit('SET_EMOTE_PHOTO', downloadURL);
              let emote = {
                emotePhoto: downloadURL,
                category: options.category,
                not_shown: false,
                name: file.name,
              };
              db.collection('emotes')
                .add(emote)
                .then((docSnapshot) => {
                  if (!docSnapshot) return;
                  let eventId = {
                    docId: docSnapshot.id,
                  };
                  db.collection('emotes').doc(docSnapshot.id).update(eventId);
                });
            }
          });
        }
      );
    },
    async UPLOAD_VIDEO_APPLICATION({ dispatch, state }, options) {
      let file = options.video;
      let storageRef = fb.storage().ref('/' + options.folder + '/' + file.name);
      let uploadTask = storageRef.put(file);
      uploadTask.on(
        'state_changed',
        // eslint-disable-next-line
        (snapshot) => {},
        // eslint-disable-next-line
        (error) => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let newApplication = {
              username: state.username,
              userId: state.userId,
              discordUsername: state.profileDiscordUsername,
              created_at: options.today,
              status: 'Pending',
              videoUrl: downloadURL,
            };

            state.accountAllGiveaways[options.giveawayId].applications.push(
              newApplication
            );

            db.collection('giveaways')
              .doc(options.userId)
              .update({
                list: state.accountAllGiveaways,
              })
              .then(
                dispatch(
                  'AccountStore/GET_ACCOUNT_GIVEAWAYS',
                  state.account.userId
                )
              );
          });
        }
      );
    },
    async UPDATE_STORE({ dispatch }, merch) {
      await db.collection('store').doc(merch.userId).update(merch);
      await dispatch('GET_STORE');
    },
    async GET_LIVE_STREAMER({ commit }, username) {
      let getData = await rtdb.ref('/streamers/' + username);
      getData.on('value', function (snapshot) {
        if (!snapshot.exists) return;
        let data = snapshot;
        commit('SET_LIVE_STREAMER', data);
      });
      commit('STREAMER_LOADED');
    },
    // async UPDATE_VIEWERS_LIST({ state }, route) {
    //   if (state.username && route === "live") {
    //     let userId = fb.auth().currentUser.uid;
    //     await rtdb.ref("viewers/" + userId).update({
    //       userId: state.userId,
    //       currentRoute: router.currentRoute.name,
    //       currentRouteId: router.currentRoute.params.id,
    //       last_changed: firebase.database.ServerValue.TIMESTAMP,
    //       username: state.username,
    //       userPhotoUrl: state.userPhotoUrl,
    //       rank: state.profileRank,
    //       profileInitials: state.profileInitials,
    //     });
    //   } else if (state.username && route !== "live") {
    //     let userId = fb.auth().currentUser.uid;
    //     rtdb.ref("/viewers/" + userId).remove();
    //   }
    // },
    async GET_CURRENT_ACCOUNT_VIEWERS({ commit, state }, user) {
      state.accountViewers = [];
      let getData = rtdb
        .ref('status/')
        .orderByChild('currentRouteId')
        .equalTo(user);
      getData.on('value', function (snapshot) {
        snapshot.forEach(function (childSnapshot) {
          if (
            !state.accountViewers.some(
              (account) => account.userId === childSnapshot.val().userId
            ) &&
            childSnapshot.val().username &&
            childSnapshot.val().state === 'online' &&
            childSnapshot.val().currentRoute === 'live'
          ) {
            const data = {
              state: childSnapshot.val().state,
              currentRoute: router.currentRoute.name,
              last_changed: childSnapshot.val().last_changed,
              username: childSnapshot.val().username,
              streamer: childSnapshot.val().streamer,
              profileInitials: childSnapshot.val().profileInitials,
              currentRouteId: router.currentRoute.params.id,
              userPhotoUrl: childSnapshot.val().userPhotoUrl,
              rank: childSnapshot.val().rank,
              userId: childSnapshot.val().userId,
            };
            commit('SET_ACCOUNT_VIEWERS', data);
          }
        });
      });
      commit('SET_ACCOUNT_VIEWERS_COUNT');
      commit('ACCOUNT_VIEWERS_LOADED');
    },
  },
});
