<template>
  <v-menu bottom left offset-y transition="scale-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon color="black">mdi-bell-outline</v-icon>
        <v-badge
          v-if="unreadNotifications.length > 0"
          color="red"
          :content="unreadNotifications.length"
          overlap
        ></v-badge>
      </v-btn>
    </template>

    <v-list width="400px" dark>
      <v-list-item
        v-for="(notification, index) in recentNotifications"
        :key="index"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ notification.title }} -
            {{ notification.creditsAmountReceived }} credits
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ notification.message }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="mt-1">
            {{ formatDate(notification.created_at) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="markAsRead(notification.docId)"
            v-if="isUnread(notification)"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="recentNotifications.length === 0">
        <v-list-item-content>No notifications</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { rtdb } from '@/firebase';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      notifications: [],
    };
  },
  computed: {
    ...mapState('UserStore', ['user']),
    unreadNotifications() {
      return this.notifications.filter((n) => !n.read);
    },
    recentNotifications() {
      return this.notifications.slice(0, 5); // Get the last 5 notifications
    },
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      this.fetchNotifications();
    },
    formatDate(timestamp) {
      if (!timestamp) return 'Unknown date';
      const date = new Date(timestamp);
      return date.toLocaleString(); // Adjust format as needed
    },
    isUnread(notification) {
      return !notification.read;
    },
    async markAsRead(notificationId) {
      try {
        await this.markNotificationAsRead(notificationId);
        // Optionally, update local state or refresh notifications list
      } catch (error) {
        console.error('Failed to mark notification as read:', error);
        // Handle the error, perhaps show a user notification
      }
    },
    async markNotificationAsRead(notificationId) {
      try {
        // Reference to the specific notification
        const notificationRef = rtdb.ref(
          'usersNotifications/' + notificationId
        );

        // Update the notification to mark it as read
        await notificationRef.update({ read: true });
      } catch (error) {
        console.error('Error marking notification as read:', error);
        throw error; // Rethrow the error for the caller to handle
      }
    },

    async fetchNotifications() {
      let unsubscribe;
      try {
        const ref = rtdb.ref('/usersNotifications/');

        // Query for notifications for the specific user
        const query = ref
          .orderByChild('username')
          .equalTo(this.user.displayName);

        unsubscribe = query.on(
          'value',
          (snapshot) => {
            try {
              if (snapshot.exists()) {
                const notifs = [];
                snapshot.forEach((childSnapshot) => {
                  const notification = childSnapshot.val();
                  notification.docId = childSnapshot.key;
                  notifs.push(notification);
                });

                // Sort by created_at descending (newest first) and take the last 5
                this.notifications = notifs
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .slice(0, 5);
              } else {
                this.notifications = [];
              }
            } catch (error) {
              console.error('Error processing notification snapshot:', error);
            }
          },
          (error) => {
            console.error('Realtime listener error:', error);
          }
        );

        return () => {
          if (unsubscribe) {
            query.off('value', unsubscribe);
          }
        };
      } catch (error) {
        console.error('Failed to set up notifications listener:', error);
        return () => {};
      }
    },
  },
};
</script>
