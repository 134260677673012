<template>
  <div class="twitch-embed-container">
    <v-btn
      color="purple"
      class="twitch-button"
      href="https://www.twitch.tv/vforce_fn"
      target="_blank"
      dark
      block
      v-show="activeIframe === 'iframe1'"
    >
      Join live vforce_fn
    </v-btn>
    <v-btn
      color="purple"
      class="twitch-button"
      href="https://www.twitch.tv/vforce_fnvods"
      target="_blank"
      dark
      block
      v-show="activeIframe === 'iframe2'"
    >
      Join live vforce_fnvods
    </v-btn>
    <div id="iframe-container">
      <iframe
        v-show="activeIframe === 'iframe1'"
        src="https://player.twitch.tv/?channel=vforce_fn&parent=vforce.gg&autoplay=true&muted=true"
        frameborder="0"
        allowfullscreen="false"
        scrolling="no"
        class="twitch-embed"
      ></iframe>
      <iframe
        v-show="activeIframe === 'iframe2'"
        src="https://player.twitch.tv/?channel=vforce_fnvods&parent=vforce.gg&autoplay=true&muted=true"
        frameborder="0"
        allowfullscreen="false"
        scrolling="no"
        class="twitch-embed"
      >
        >
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwitchEmbedComponent',
  data() {
    return {
      activeIframe: 'iframe1', // Start with the first iframe
      intervalId: null, // To store the interval ID for cleanup
    };
  },
  mounted() {
    // Start the rotation when the component is mounted
    this.startRotation();
  },
  beforeDestroy() {
    // Clean up the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    startRotation() {
      this.intervalId = setInterval(() => {
        // Toggle between iframe1 and iframe2
        this.activeIframe =
          this.activeIframe === 'iframe1' ? 'iframe2' : 'iframe1';
      }, 30000); // 30 seconds
    },
  },
};
</script>

<style scoped>
.twitch-embed-container {
  position: fixed; /* Kept as fixed per your design */
  bottom: 0px;
  right: 0px;
  z-index: 1000;
}

#iframe-container {
  position: relative; /* Added to stack iframes in the same space */
  width: 320px; /* Matches your iframe width */
  height: 180px; /* Matches your iframe height */
}

.twitch-embed {
  width: 320px;
  height: 180px;
  position: absolute; /* Overlap iframes in the container */
  top: 0;
  left: 0;
}

.twitch-button {
  width: 200px;
}

@media screen and (max-width: 768px) {
  #iframe-container {
    width: 200px; /* Matches your responsive iframe width */
    height: 113px; /* Matches your responsive iframe height */
  }
  .twitch-embed {
    width: 200px; /* Smaller width for mobile */
    height: 113px; /* Maintaining the 16:9 aspect ratio */
  }
}
</style>
