import { rtdb } from '@/firebase';

const state = {
  rtdbTournaments: [],
  lastKeyRtdb: null,
  loadingRtdb: false,
  errorRtdb: null,

  rtdbSelectedCoinsWinners: [],
  rtdbSelectedPrizeWinners: [],
  rtdbAllBettings: [],
  selectedRtdbTourney: {},
  filteredBettings: [],
  filteredBettingsValue: 0,
  filteredBoosts: [],
  filteredBoostsValue: 0,
  coinsParticipant: null,
  candidateParticipant: null,
  rtdbCoinsParticipants: [],
  tourneyBettingsLoaded: false,
  bettingsSelectedBoosts: '' || 'goldCoinsBoosts',
  bettingsTab: 0,
  rtdbTournamentsLoaded: false, // New state to track if RTDB tournaments have been loaded
};

const mutations = {
  SET_TOURNAMENTS_RTDB(state, tournaments) {
    state.rtdbTournaments = tournaments;
  },
  ADD_TOURNAMENTS_RTDB(state, newTournaments) {
    state.rtdbTournaments = [...state.rtdbTournaments, ...newTournaments];
  },
  SET_LAST_KEY_RTDB(state, key) {
    state.lastKeyRtdb = key;
  },
  SET_LOADING_RTDB(state, status) {
    state.loadingRtdb = status;
  },
  SET_ERROR_RTDB(state, error) {
    state.errorRtdb = error;
  },
  SET_RTDB_TOURNEY(state, payload) {
    let profileRank = '';
    if (payload.profileFortniteBuildsLevel) {
      if (payload.profileFortniteBuildsLevel.includes('Diamond')) {
        profileRank = 'diamondCoinsParticipants';
      } else if (payload.profileFortniteBuildsLevel.includes('Gold')) {
        profileRank = 'goldCoinsParticipants';
      } else if (payload.profileFortniteBuildsLevel.includes('Silver')) {
        profileRank = 'silverCoinsParticipants';
      } else if (payload.profileFortniteBuildsLevel.includes('Bronze')) {
        profileRank = 'bronzeCoinsParticipants';
      }
    }

    state.rtdbAllBettings = payload.data.val();
    state.rtdbCoinsParticipants = [
      ...(state.rtdbAllBettings.diamondCoinsParticipants || []),
      ...(state.rtdbAllBettings.goldCoinsParticipants || []),
      ...(state.rtdbAllBettings.silverCoinsParticipants || []),
      ...(state.rtdbAllBettings.bronzeCoinsParticipants || []),
    ];
    state.rtdbCoinsBoosts = [
      ...(state.rtdbAllBettings.diamondCoinsBoosts || []),
      ...(state.rtdbAllBettings.goldCoinsBoosts || []),
      ...(state.rtdbAllBettings.silverCoinsBoosts || []),
      ...(state.rtdbAllBettings.bronzeCoinsBoosts || []),
    ];
    if (
      state.rtdbAllBettings.candidates &&
      state.rtdbAllBettings.candidates.length
    ) {
      const candidateParticipantHolder = state.rtdbAllBettings.candidates.some(
        (item) => item.username === payload.username && item.status
      );
      if (candidateParticipantHolder) {
        state.candidateParticipant = true;
      } else {
        state.candidateParticipant = false;
      }
    }
    if (
      state.rtdbAllBettings[profileRank] &&
      state.rtdbAllBettings[profileRank].length
    ) {
      const coinsParticipantHolder = state.rtdbAllBettings[profileRank].some(
        (item) => item.username === payload.username && item.status
      );
      if (coinsParticipantHolder) {
        state.coinsParticipant = true;
      } else {
        state.coinsParticipant = false;
      }
    }

    if (payload.tourney) {
      state.selectedRtdbTourney = payload.tourney;
    }
  },

  RTDB_TOURNAMENTS_LOADED(state, status) {
    state.rtdbTournamentsLoaded = status;
  },
  // UPDATES
  UPDATE_BETTINGS_RANKS(state, payload) {
    state.bettingsSelectedBoosts = payload.bettingsSelectedBoosts;
    state.bettingsTab = payload.bettingsTab;
  },
  UPDATE_RTDB_RANK_BETTINGS(state) {
    state.filteredBettings = [];
    state.filteredBettingsValue = 0;
    state.filteredBoosts = [];
    state.filteredBoostsValue = 0;

    if (
      (state.rtdbAllBettings[state.bettingsSelectedBoosts] &&
        state.rtdbAllBettings[state.bettingsSelectedBoosts].length) ||
      state.bettingsTab === 4
    ) {
      let filteredBoosts;
      if (state.bettingsTab < 4) {
        filteredBoosts = state.rtdbAllBettings[state.bettingsSelectedBoosts];
      } else if (state.bettingsTab === 4) {
        filteredBoosts = [
          ...state.rtdbAllBettings['diamondCoinsBoosts'],
          ...state.rtdbAllBettings['goldCoinsBoosts'],
          ...state.rtdbAllBettings['silverCoinsBoosts'],
          ...state.rtdbAllBettings['bronzeCoinsBoosts'],
        ];
      }
      state.filteredBoosts = filteredBoosts;
      state.filteredBoostsValue = filteredBoosts.length * 20;
    }

    if (
      state.rtdbAllBettings.sponsors &&
      state.rtdbAllBettings.sponsors.length
    ) {
      let filteredBettings;
      let filteredCoinsParticipants;
      if (state.bettingsTab === 0) {
        filteredBettings = state.rtdbAllBettings.sponsors.filter(
          (sponsor) => sponsor.status && sponsor.gameLevel.includes('Diamond')
        );
        filteredCoinsParticipants = Array.isArray(
          state.rtdbAllBettings.diamondCoinsParticipants
        )
          ? state.rtdbAllBettings.diamondCoinsParticipants.filter(
              (sponsor) => sponsor.status
            )
          : [];
      } else if (state.bettingsTab === 1) {
        filteredBettings = state.rtdbAllBettings.sponsors.filter(
          (sponsor) => sponsor.status && sponsor.gameLevel.includes('Gold')
        );
        filteredCoinsParticipants = Array.isArray(
          state.rtdbAllBettings.silverCoinsParticipants
        )
          ? state.rtdbAllBettings.silverCoinsParticipants.filter(
              (sponsor) => sponsor.status
            )
          : [];
      } else if (state.bettingsTab === 2) {
        filteredBettings = state.rtdbAllBettings.sponsors.filter(
          (sponsor) => sponsor.status && sponsor.gameLevel.includes('Silver')
        );
        filteredCoinsParticipants = Array.isArray(
          state.rtdbAllBettings.silverCoinsParticipants
        )
          ? state.rtdbAllBettings.silverCoinsParticipants.filter(
              (sponsor) => sponsor.status
            )
          : [];
      } else if (state.bettingsTab === 3) {
        filteredBettings = state.rtdbAllBettings.sponsors.filter(
          (sponsor) => sponsor.status && sponsor.gameLevel.includes('Bronze')
        );
        filteredCoinsParticipants = Array.isArray(
          state.rtdbAllBettings.bronzeCoinsParticipants
        )
          ? state.rtdbAllBettings.bronzeCoinsParticipants.filter(
              (sponsor) => sponsor.status
            )
          : [];
      } else if (state.bettingsTab === 4) {
        let allCoinsParticipants = [
          ...(state.rtdbAllBettings.diamondCoinsParticipants || []),
          ...(state.rtdbAllBettings.goldCoinsParticipants || []),
          ...(state.rtdbAllBettings.silverCoinsParticipants || []),
          ...(state.rtdbAllBettings.bronzeCoinsParticipants || []),
        ];
        filteredBettings = state.rtdbAllBettings.sponsors.filter(
          (sponsor) => sponsor.status
        );

        filteredCoinsParticipants = allCoinsParticipants.filter(
          (sponsor) => sponsor.status
        );
      }
      state.filteredBettings = [
        ...filteredBettings,
        ...filteredCoinsParticipants,
      ];
      state.filteredBettingsValue = state.filteredBettings.length * 20;
    }
  },
  // LOADERS
  RTDB_TOURNEY_LOADED(state) {
    state.tourneyBettingsLoaded = true;
  },
  UPDATE_RTDB_TOURNAMENT(state, payload) {
    const tournamentIndex = state.rtdbTournaments.findIndex(
      (tournament) => tournament.docId === payload.docId
    );

    if (tournamentIndex !== -1) {
      // Update the tournament at the found index with the new data from payload
      state.rtdbTournaments[tournamentIndex].participantsCount =
        payload.participantsCount;
    } else {
      console.error('Tournament not found with docId:', payload.docId);
    }
  },
};

const getters = {
  getrtdbTournaments: (state) => state.rtdbTournaments,
  getLoadingRtdb: (state) => state.loadingRtdb,
  getErrorRtdb: (state) => state.errorRtdb,
  getLastKeyRtdb: (state) => state.lastKeyRtdb,
};

const actions = {
  async GET_RTDB_TOURNAMENTS({ commit }) {
    commit('SET_LOADING_RTDB', true);
    commit('SET_ERROR_RTDB', null);
    commit('RTDB_TOURNAMENTS_LOADED', false); // Set loaded status to false at the start

    try {
      const ref = rtdb.ref('tournaments');

      // Initial fetch to set up the data
      const initialSnapshot = await ref.once('value');

      if (initialSnapshot.exists()) {
        let tournaments = Object.entries(initialSnapshot.val())
          .map(([key, value]) => ({ docId: key, ...value }))
          .reverse(); // Reverse to get the most recent entries first

        commit('SET_TOURNAMENTS_RTDB', tournaments);
        commit('SET_LAST_KEY_RTDB', tournaments[0]?.docId || null);
        commit('RTDB_TOURNAMENTS_LOADED', true); // Set loaded status to true when initial data is fetched
      } else {
        commit('SET_TOURNAMENTS_RTDB', []);
        commit('SET_LAST_KEY_RTDB', null);
        commit('RTDB_TOURNAMENTS_LOADED', true); // Set loaded status to true even if there's no data
      }

      // Listen for real-time updates
      const unsubscribe = ref.on('value', (snapshot) => {
        if (snapshot.exists()) {
          let tournaments = Object.entries(snapshot.val())
            .map(([key, value]) => ({ docId: key, ...value }))
            .reverse();

          commit('SET_TOURNAMENTS_RTDB', tournaments);
          commit('SET_LAST_KEY_RTDB', tournaments[0]?.docId || null);
        } else {
          commit('SET_TOURNAMENTS_RTDB', []);
          commit('SET_LAST_KEY_RTDB', null);
        }
      });

      // Return a function to unsubscribe from the listener when needed
      return () => {
        ref.off('value', unsubscribe);
      };
    } catch (error) {
      commit('SET_ERROR_RTDB', error.message);
    } finally {
      commit('SET_LOADING_RTDB', false);
    }
  },

  async GET_MORE_TOURNEYS_RTDB({ commit, state }) {
    if (!state.lastKeyRtdb) return;

    commit('SET_LOADING_RTDB', true);
    commit('SET_ERROR_RTDB', null);
    try {
      const ref = rtdb
        .ref('tourneys')
        .endAt(null, state.lastKeyRtdb)
        .limitToLast(9); // Fetch one extra to exclude the lastKey

      const snapshot = await ref.once('value');

      if (snapshot.exists()) {
        let newTournaments = [];
        snapshot.forEach((childSnapshot) => {
          if (childSnapshot.key !== state.lastKeyRtdb) {
            newTournaments.unshift({
              id: childSnapshot.key,
              ...childSnapshot.val(),
            });
          }
        });

        // Only keep 8 new tournaments, excluding the one with lastKey
        newTournaments = newTournaments.slice(0, 8);

        commit('ADD_TOURNAMENTS_RTDB', newTournaments);
        commit('SET_LAST_KEY_RTDB', newTournaments[0]?.id || null); // Update lastKey with the newest tournament key or null if no more
      } else {
        commit('SET_LAST_KEY_RTDB', null);
      }
    } catch (error) {
      commit('SET_ERROR_RTDB', error.message);
    } finally {
      commit('SET_LOADING_RTDB', false);
    }
  },
  async GET_RTDB_TOURNEY(
    { commit, state },
    tourney,
    profileFortniteBuildsLevel
  ) {
    state.rtdbSelectedCoinsWinners = [];
    state.rtdbSelectedPrizeWinners = [];
    let getData = await rtdb.ref('/tourneys/' + tourney.docId);
    getData.on('value', function (snapshot) {
      let data = snapshot;
      commit('SET_RTDB_TOURNEY', { data, tourney, profileFortniteBuildsLevel });
      commit('UPDATE_RTDB_RANK_BETTINGS');
    });
    commit('RTDB_TOURNEY_LOADED');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
