import { rtdb } from '@/firebase';

const state = {
  rtdbAccountCoins: 0,
  rtdbAccountCredits: 0,

  // LOADERS
  rtdbAccountLoaded: false,
};
const mutations = {
  // SETTERS
  SET_RTDB_ACCOUNT(state, payload) {
    state.rtdbAccountCoins = payload.val().credits;
    state.rtdbAccountCredits = payload.val().credits;
  },

  // CRUD RTBD SETTERS
  UPDATE_RTDB_ACCOUNT_CREDITS(state, payload) {
    state.rtdbAccountCredits = payload;
  },
  UPDATE_RTDB_ACCOUNT_COINS(state, payload) {
    state.rtdbAccountCoins = payload;
  },
  //LOADERS
  RTDB_ACCOUNT_LOADED(state) {
    state.rtdbAccountLoaded = true;
  },
};
const getters = {};
const actions = {
  async GET_RTDB_ACCOUNT({ commit }, user) {
    try {
      // Fetch account from RTDB
      const ref = rtdb.ref(`/accounts/${user.uid}`);
      const snapshot = await ref.once('value');

      if (!snapshot.exists()) {
        // No account found; handle accordingly
        console.warn(`No account found for username: ${user.uid}`);
        return;
      }

      // Commit account data to the store
      commit('SET_RTDB_ACCOUNT', snapshot);
      commit('RTDB_ACCOUNT_LOADED');
    } catch (error) {
      // Handle any errors that occur during the process
      console.error('Error fetching account from RTDB:', error);
      // Optionally, you can redirect to an error page or show an error message
    }
  },
};
export default { namespaced: true, state, getters, actions, mutations };
