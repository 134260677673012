import { db, rtdb } from '@/firebase';
import _ from 'lodash';

const state = {
  accountWonTourneys: [],
  allMoneyTourneys: [],
  activeTourneys: [],
  tourney: {},
  tournaments: [],
  lastVisible: null,
  loading: false, // Optional, for UI feedback
  error: null,

  // LOADERS
  tourneyLoaded: false,
  activeTourneysLoaded: false,
  rtdbActiveTourneyBriefLoaded: false,
  rtdbLatestTourneyBriefLoaded: false,
  tournamentsLoaded: false, // New state to track if tournaments have been loaded
};

const mutations = {
  // SETTERS
  SET_TOURNAMENTS(state, tournaments) {
    state.tournaments = tournaments;
  },
  ADD_TOURNAMENTS(state, newTournaments) {
    state.tournaments = [...state.tournaments, ...newTournaments];
  },
  SET_LAST_VISIBLE(state, doc) {
    state.lastVisible = doc;
  },
  SET_LOADING(state, status) {
    state.loading = status;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_ACCOUNT_WON_TOURNEYS(state, payload) {
    state.accountWonTourneys.push(payload.data());
  },
  SET_TOURNEY(state, payload) {
    state.tourney = payload;
  },
  TOURNEY_LOADED(state) {
    state.tourneyLoaded = true;
  },
  SET_ACTIVE_TOURNEYS(state, payload) {
    state.activeTourneys.push(payload);

    state.activeTourneys = _.orderBy(
      state.activeTourneys,
      ['buyInCoinsAmount', 'active', 'participants.length', 'created_at'],
      ['asc', 'desc', 'desc', 'desc']
    );
  },

  SET_LATEST_TOURNEYS(state, payload) {
    state.latestTourneys.push(payload);

    state.latestTourneys = _.orderBy(
      state.latestTourneys,
      ['buyInCoinsAmount', 'active', 'participants.length', 'created_at'],
      ['asc', 'desc', 'desc', 'desc']
    );
  },
  SET_RTDB_ACTIVE_TOURNEY_BRIEF(state, payload) {
    let index = state.activeTourneys.findIndex((tourney) => {
      return tourney.docId === payload.val().docId;
    });

    if (index !== -1) {
      // Ensure that 'participants' is set on the tourney if it doesn't exist
      if (!state.activeTourneys[index].participants) {
        state.activeTourneys[index].participants = 0;
      }

      state.activeTourneys[index].participants =
        payload.val().participants || 0;
      state.activeTourneys[index].totalAmount = payload.val().totalAmount || 0;
    } else {
      // If no matching tourney is found, you can optionally log a message or handle the case
      console.error('Tourney not found with docId:', payload.val().docId);
    }
  },
  SET_RTDB_LATEST_TOURNEY_BRIEF(state, payload) {
    let index = state.latestTourneys.findIndex((tourney) => {
      return tourney.docId === payload.val().docId;
    });

    if (index !== -1) {
      // Ensure that 'participants' is set on the tourney if it doesn't exist
      if (!state.latestTourneys[index].participants) {
        state.latestTourneys[index].participants = 0;
      }

      state.latestTourneys[index].participants =
        payload.val().participants || 0;
      state.latestTourneys[index].totalAmount = payload.val().totalAmount || 0;
    } else {
      // If no matching tourney is found, you can optionally log a message or handle the case
      console.error('Tourney not found with docId:', payload.val().docId);
    }
  },

  // ACTIONS
  ARCHIVE_TOURNEY(state, payload) {
    const index = state.accountTourneys.findIndex((tourney) => {
      return tourney.docId === payload.docId;
    });
    state.accountTourneys[index].active = false;
  },
  // LOADERS

  RTDB_LATEST_TOURNEY_BRIEF_LOADED(state) {
    state.rtdbLatestTourneyBriefLoaded = true;
  },
  ACTIVE_TOURNEYS_LOADED(state) {
    state.activeTourneysLoaded = true;
  },

  SET_LOADED_TOURNAMENTS(state, status) {
    // New mutation for tracking loaded status
    state.tournamentsLoaded = status;
  },
};

const getters = {
  getTournaments: (state) => state.tournaments,
  getLoading: (state) => state.loading,
  getError: (state) => state.error,
};

const actions = {
  async GET_LATEST_TOURNEYS({ commit }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    commit('SET_LOADED_TOURNAMENTS', false); // Set loaded status to false at the start

    try {
      const query = db
        .collection('tournaments')
        .where('isListed', '==', true)
        .where('isArchived', '==', false)
        .orderBy('endDate', 'desc')
        .limit(4);

      const querySnapshot = await query.get();

      if (!querySnapshot.empty) {
        const tournaments = querySnapshot.docs.map((doc) => ({
          docId: doc.id,
          ...doc.data(),
        }));
        commit('SET_TOURNAMENTS', tournaments);

        commit(
          'SET_LAST_VISIBLE',
          querySnapshot.docs[querySnapshot.docs.length - 1]
        );
      } else {
        commit('SET_TOURNAMENTS', []);
        commit('SET_LAST_VISIBLE', null);
      }
      commit('SET_LOADED_TOURNAMENTS', true); // Set loaded status to true when data is fetched
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async GET_NEXT_TOURNEYS({ commit, state }) {
    if (!state.lastVisible) return;

    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const query = db
        .collection('tournaments')
        .orderBy('created_at', 'desc')
        .startAfter(state.lastVisible)
        .limit(4);

      const querySnapshot = await query.get();

      if (!querySnapshot.empty) {
        const newTourneys = querySnapshot.docs.map((doc) => ({
          docId: doc.id,
          ...doc.data(),
        }));
        commit('ADD_TOURNAMENTS', newTourneys);
        commit(
          'SET_LAST_VISIBLE',
          querySnapshot.docs[querySnapshot.docs.length - 1]
        );
      } else {
        commit('SET_LAST_VISIBLE', null);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async GET_ACTIVE_TOURNEYS({ dispatch, commit, state }) {
    try {
      const dataBase = await db
        .collection('tournaments')
        .where('visibility', '==', true);
      const dbResults = await dataBase.get();

      if (!dbResults.empty) {
        // Create an array of promises for each tourney processing
        const tourneyPromises = dbResults.docs.map(async (doc) => {
          const tourney = doc.data();

          if (
            !state.activeTourneys.some((tourney) => tourney.docId === doc.id)
          ) {
            commit('SET_ACTIVE_TOURNEYS', doc.data());
            // Fetch related bets for each tourney (await dispatch)
            await dispatch('GET_RTDB_ACTIVE_TOURNEY_BRIEF', tourney);
          }
        });

        // Wait for all the tourney promises (including dispatch) to finish
        await Promise.all(tourneyPromises);
        commit('ACTIVE_TOURNEYS_LOADED');
      }
    } catch (error) {
      console.error('Error fetching account tournaments:', error);
    }
  },

  async GET_RTDB_LATEST_TOURNEY_BRIEF({ commit }, tourney) {
    return new Promise((resolve, reject) => {
      // Fetch data from the real-time database
      const getData = rtdb.ref('/tourneysBrief/' + tourney.docId);

      getData.once(
        'value',
        function (snapshot) {
          if (snapshot.exists()) {
            // Commit the data if it exists
            commit('SET_RTDB_LATEST_TOURNEY_BRIEF', snapshot);
          }

          // Commit mutation indicating that the RTDB tournament data has been loaded
          commit('RTDB_LATEST_TOURNEY_BRIEF_LOADED');

          // Resolve the promise once all actions are completed
          resolve();
        },
        reject
      ); // Reject the promise if there's an error during the fetch
    });
  },

  async GET_TOURNEY({ commit }, docId) {
    const dataBase = await db.collection('tournaments').doc(docId);
    const dbResults = await dataBase.get();
    const tourney = dbResults.data();
    commit('SET_TOURNEY', tourney);
    commit('TOURNEY_LOADED');
  },
  async GET_ACCOUNT_WON_TOURNEYS({ commit, state }, user) {
    state.accountWonTourneys = [];
    let dataBase = await db
      .collection('tournaments')
      .where('usernames', 'array-contains', user)
      .orderBy('deadline', 'desc')
      .limit(6);
    const dbResults = await dataBase.get();
    dbResults.forEach((doc) => {
      if (
        !state.accountWonTourneys.some((tourney) => tourney.docId === doc.docId)
      ) {
        commit('SET_ACCOUNT_WON_TOURNEYS', doc);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
