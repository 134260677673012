import { db } from '@/firebase'; // Adjust the import path as necessary
// import _ from 'lodash';

const state = {
  lastTournamentDoc: null,
  accountTournaments: [],
  accountTournamentsLoaded: false,
  unlistedTournaments: [],
  unlistedTournamentsLoaded: false,
  archivedTournaments: [],
  archivedTournamentsLoaded: false,
  accountTournamentParticipants: [],
  accountTournamentParticipantsLoaded: false,
};

const mutations = {
  APPEND_ACCOUNT_TOURNAMENTS(state, tournaments) {
    state.accountTournaments = [...state.accountTournaments, ...tournaments];
  },
  SET_LAST_TOURNAMENT_DOC(state, doc) {
    state.lastTournamentDoc = doc;
  },
  SET_ACCOUNT_TOURNAMENTS(state, payload) {
    state.accountTournaments = payload;
  },
  ACCOUNT_TOURNAMENTS_LOADED(state) {
    state.accountTournamentsLoaded = true;
  },
  SET_ACCOUNT_UNLISTED_TOURNAMENTS(state, payload) {
    state.unlistedTournaments = payload;
  },
  ACCOUNT_UNLISTED_TOURNAMENTS_LOADED(state) {
    state.unlistedTournamentsLoaded = true;
  },
  SET_ACCOUNT_ARCHIVED_TOURNAMENTS(state, payload) {
    state.archivedTournaments = payload;
  },
  ACCOUNT_ARCHIVED_TOURNAMENTS_LOADED(state) {
    state.archivedTournamentsLoaded = true;
  },
  SET_TOURNAMENT_STATUS(state, tournament) {
    // Initialize indices for all relevant arrays
    const listedIndex = state.accountTournaments.findIndex(
      (t) => t.docId === tournament.docId
    );
    const unlistedIndex = state.unlistedTournaments.findIndex(
      (t) => t.docId === tournament.docId
    );
    const archivedIndex = state.archivedTournaments.findIndex(
      (t) => t.docId === tournament.docId
    );

    // Remove tournament from any array if it's there
    if (listedIndex !== -1) state.accountTournaments.splice(listedIndex, 1);
    if (unlistedIndex !== -1)
      state.unlistedTournaments.splice(unlistedIndex, 1);
    if (archivedIndex !== -1)
      state.archivedTournaments.splice(archivedIndex, 1);

    // Add tournament to the correct array based on its status
    if (tournament.isArchived) {
      state.archivedTournaments.push({
        ...tournament,
        docId: tournament.docId,
      });
    } else if (tournament.isListed) {
      state.accountTournaments.push({
        ...tournament,
        docId: tournament.docId,
      });
    } else {
      state.unlistedTournaments.push({
        ...tournament,
        docId: tournament.docId,
      });
    }
  },
  CLEAR_ACCOUNT_TOURNAMENTS(state) {
    state.accountTournaments = [];
  },
  SET_ACCOUNT_TOURNAMENT_PARTICIPANTS(state, participants) {
    state.accountTournamentParticipants = participants;
  },
  ACCOUNT_TOURNAMENT_PARTICIPANTS_LOADED(state) {
    state.accountTournamentParticipantsLoaded = true;
  },
};

const actions = {
  async GET_ACCOUNT_TOURNAMENTS({ commit }, username) {
    try {
      commit('CLEAR_ACCOUNT_TOURNAMENTS');

      const query = db
        .collection('tournaments')
        .where('username', '==', username)
        .where('isListed', '==', true)
        .where('isArchived', '==', false)
        .orderBy('endDate', 'desc')
        .limit(4);

      const querySnapshot = await query.get();

      if (!querySnapshot.empty) {
        const tournaments = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          docId: doc.id,
        }));
        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]; // Store last document
        commit('SET_ACCOUNT_TOURNAMENTS', tournaments);
        commit('SET_LAST_TOURNAMENT_DOC', lastDoc); // Save last doc for pagination
        commit('ACCOUNT_TOURNAMENTS_LOADED');
      } else {
        commit('SET_LAST_TOURNAMENT_DOC', null); // No more to load
        commit('ACCOUNT_TOURNAMENTS_LOADED');
      }
    } catch (error) {
      console.error('Error fetching account tournaments:', error);
    }
  },
  async GET_NEXT_ACCOUNT_TOURNAMENTS({ commit, state }, username) {
    try {
      const lastDoc = state.lastTournamentDoc; // Get last document from state
      if (!lastDoc) {
        console.log('No more tournaments to load.');
        return;
      }

      const query = db
        .collection('tournaments')
        .where('username', '==', username) // Assuming username is stored in state
        .where('isListed', '==', true)
        .where('isArchived', '==', false)
        .orderBy('endDate', 'desc')
        .startAfter(lastDoc) // Start after the last fetched document
        .limit(4);

      const querySnapshot = await query.get();

      if (!querySnapshot.empty) {
        const tournaments = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          docId: doc.id,
        }));
        const newLastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]; // Update last document
        commit('APPEND_ACCOUNT_TOURNAMENTS', tournaments); // Append new tournaments
        commit('SET_LAST_TOURNAMENT_DOC', newLastDoc); // Update last doc
      } else {
        commit('SET_LAST_TOURNAMENT_DOC', null); // No more to load
      }
    } catch (error) {
      console.error('Error fetching next account tournaments:', error);
    }
  },
  async GET_ACCOUNT_TOURNAMENT_PARTICIPANTS({ commit }, tournamentDocId) {
    try {
      const docRef = db
        .collection('tournamentsParticipants')
        .doc(tournamentDocId);
      const doc = await docRef.get();

      if (doc.exists) {
        let data = doc.data().participantsList;

        commit('SET_ACCOUNT_TOURNAMENT_PARTICIPANTS', data);
      }

      commit('ACCOUNT_TOURNAMENT_PARTICIPANTS_LOADED');
    } catch (error) {
      console.error('Error fetching tournament data from Firestore:', error);
      // Optionally, handle this error state in your UI or store
    }
  },
  async GET_ACCOUNT_UNLISTED_TOURNAMENTS({ commit }, username) {
    try {
      const query = db
        .collection('tournaments')
        .where('username', '==', username)
        .where('isListed', '==', false)
        .where('isArchived', '==', false)
        .orderBy('endDate', 'desc')
        .limit(8);

      const querySnapshot = await query.get();

      if (!querySnapshot.empty) {
        const tournaments = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          docId: doc.id,
        }));
        commit('SET_ACCOUNT_UNLISTED_TOURNAMENTS', tournaments);
        commit('ACCOUNT_UNLISTED_TOURNAMENTS_LOADED');
      }
    } catch (error) {
      console.error('Error fetching unlisted account tournaments:', error);
    }
  },
  async GET_ACCOUNT_ARCHIVED_TOURNAMENTS({ commit }, username) {
    try {
      const query = db
        .collection('tournaments')
        .where('username', '==', username)
        .where('isArchived', '==', true)
        .orderBy('endDate', 'desc')
        .limit(8);

      const querySnapshot = await query.get();

      if (!querySnapshot.empty) {
        const tournaments = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          docId: doc.id,
        }));
        commit('SET_ACCOUNT_ARCHIVED_TOURNAMENTS', tournaments);
        commit('ACCOUNT_ARCHIVED_TOURNAMENTS_LOADED');
      }
    } catch (error) {
      console.error('Error fetching archived account tournaments:', error);
    }
  },
  async MANAGE_TOURNAMENT_VISIBILITY({ commit }, { tournament, action }) {
    try {
      const docRef = db.collection('tournaments').doc(tournament.docId);

      // Determine new status based on the action
      let updateData = {};
      switch (action) {
        case 'publish':
          updateData = { isListed: true, isArchived: false };
          break;
        case 'privatize':
          updateData = { isListed: false, isArchived: false };
          break;
        case 'archive':
          updateData = { isListed: false, isArchived: true };
          break;
        default:
      }

      await docRef.update(updateData);

      // Commit mutation to update the state based on the new status
      commit('SET_TOURNAMENT_STATUS', { ...tournament, ...updateData });
    } catch (error) {
      console.error('Failed to manage tournament visibility:', error);
      throw error;
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
