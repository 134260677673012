const state = {
  socials: [
    {
      icon: 'mdi-youtube',
      from: 'mdi',
      color: '#FF0000',
      name: 'YouTube',
      link: '',
    },
    {
      icon: 'mdi-instagram',
      from: 'mdi',
      color: '#e95950',
      name: 'Instagram',
      link: '',
    },
    {
      icon: 'tiktok.svg',
      from: 'simpleIcons',
      color: 'black',
      name: 'TikTok',
      link: '',
    },
    {
      icon: 'mdi-twitter',
      from: 'mdi',
      color: '#00acee',
      name: 'Twitter',
      link: '',
    },
    {
      icon: 'mdi-facebook',
      from: 'mdi',
      color: 'blue',
      name: 'Facebook',
      link: '',
    },
    {
      icon: 'mdi-web',
      from: 'mdi',
      color: 'black',
      name: 'Website',
      link: '',
    },
    {
      icon: 'discord.svg',
      from: 'simpleIcons',
      color: 'black',
      name: 'Discord',
      link: '',
    },
  ],
  socialsVforce: [
    {
      icon: 'mdi-twitch',
      from: 'mdi',
      color: 'purple',
      name: 'Twitch',
      link: 'https://twitch.tv/vforce_fn',
    },
    {
      icon: 'mdi-youtube',
      from: 'mdi',
      color: '#FF0000',
      name: 'YouTube',
      link: 'https://youtube.com/@vforce_fn',
    },
    {
      icon: 'mdi-instagram',
      from: 'mdi',
      color: '#e95950',
      name: 'Instagram',
      link: 'https://instagram.com/vforce_fn',
    },
    {
      icon: 'tiktok.svg',
      from: 'simpleIcons',
      color: 'black',
      name: 'TikTok',
      link: 'https://tiktok.com/@vforce_fn',
    },
    {
      icon: 'mdi-twitter',
      from: 'mdi',
      color: '#00acee',
      name: 'Twitter',
      link: 'https://twitter.com/vforce_fn',
    },
    {
      icon: 'discord.svg',
      from: 'simpleIcons',
      color: 'black',
      name: 'Discord',
      link: 'dsc.gg/vforce-fn',
    },
  ],
  tourneyRules: [
    {
      title: 'Cheating',
      text: 'Any form of cheating, including using hacks, external software, or exploiting glitches, is strictly prohibited. Players caught cheating will be immediately disqualified.',
    },
    {
      title: 'Account Sharing',
      text: 'Players should not share their accounts with others during the tournament. Each player must play using their own account.',
    },
    {
      title: 'Collusion',
      text: 'Colluding with other players to manipulate the outcome of the match is not allowed. Players should compete to the best of their ability without conspiring with opponents.',
    },
    {
      title: 'Respectful Behavior',
      text: 'Players are expected to maintain respectful behavior throughout the tournament. Any form of harassment, abuse, or unsportsmanlike conduct can lead to disqualification.',
    },
    {
      title: 'Roster Violations',
      text: 'In team-based tournaments, players must adhere to the roster and team composition rules provided by the organizers. Using unregistered or ineligible players can result in disqualification.',
    },
    {
      title: 'Match-fixing',
      text: 'Intentionally losing matches or conspiring with opponents to determine the match outcome is strictly forbidden.',
    },
    {
      title: 'Disconnects and Technical Issues',
      text: "Players should be aware of the tournament's rules regarding disconnects and technical issues. Some tournaments may allow restarts, while others might not.",
    },
    {
      title: 'Streaming and Communication',
      text: "Players should follow the tournament's guidelines regarding streaming, communication, and disclosure of information during the event.",
    },
    {
      title: 'Timeliness',
      text: 'Players must be punctual and adhere to the schedule provided by the tournament organizers. Failure to show up on time for matches can lead to disqualification.',
    },
    {
      title: 'Use of Prohibited Items',
      text: ' Some tournaments may have specific rules about the use of certain items, weapons, or skins. Players should be aware of these restrictions to avoid disqualification.',
    },
  ],
};

const mutations = {};

const getters = {};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
